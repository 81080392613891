@charset "utf-8";
/*basic*/
*{-webkit-tap-highlight-color:transparent;-webkit-touch-callout:none;/* -webkit-text-size-adjust:none; 阻止屏幕旋转时字体自动调整*/}
body,button,input,select,textarea{font-family:"Arial","Microsoft YaHei","黑体","宋体",sans-serif;color: #333;-webkit-user-select: none;}
input,textarea{-webkit-user-select:auto;user-select: auto;}
article,aside,body,dd,dl,dt,fieldset,footer,h1,h2,h3,h4,h5,h6,header,hgroup,input,legend,li,menu,nav,ol,p,pre,section,td,th,ul,textarea{margin:0;padding:0;}
em,i{font-style:normal;}
li{list-style:none;}
img,fieldset{border:0;}
input,textarea,button,select,a{outline:0;-webkit-tap-highlight-color:rgba(255, 255, 255, 0);-webkit-appearance:none;border:none;}
input,textarea{resize:none;}
input:focus{outline:0;}
input:disabled{background:none;}
a{text-decoration:none;}
a:hover,a:active{text-decoration:none;outline:0;}
html,body{position:relative;height: 100%;width: 100%;overflow-x: hidden;background-color:#f1f1f1;}
body{background-color: #fff !important;}
.clearfix:after,.wrap:after{content:"";display:block;height:0;clear:both;visibility:hidden;}
.hide-text{font:0/0;color:transparent;text-shadow:none;background-color:transparent;border:0;}
.text-ellipsis{display:block;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
.text-line1{overflow: hidden;display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical;text-overflow: ellipsis;}
.text-line2{overflow: hidden;display:-webkit-b;-webkit-line-clamp:2;-webkit-box-orient:vertical;text-overflow: ellipsis;}
.show{display: block !important;}
.hide{display: none !important;}
.fl{float: left;}
.fr{float: right;}
.bg-eee{background:#eee;}
.bg-fff{background:#fff;height: 100%;width:100%;}
.bg-poster{background:rgba(0,0,0,0.4);height: 100%;width:100%;}
.wrap{width:100%;margin:0 auto;}

.shareNum{position: absolute;color: red;font-size: 0.2rem;display: flex;align-items: flex-end;font-weight: bold;top: 0.34rem;width: 1.2rem;justify-content: center;padding-left: 0.02rem;}
.shareNumItem{font-size: 0.20rem; margin-top: 0.04rem;}
.popbg{position: fixed;top:0;right:0;left:0;bottom:0;background-color:rgba(0,0,0,0.65);z-index: 999;}
.popbg .share-img{width:7.3867rem;float: right;position: absolute;top: 0;right: 0;}

/*public*/
body{background:#fff;}

/* 暑假工 */
.slide-img{ width: 100%;height: 100%;border-radius:20rpx;}

.position-details .job-item{background:#fff;padding:0.3rem;border-bottom: 0.2rem solid #f5f5f5;}
.position-details .job-item .title{color:#333;font-size:0.32rem;line-height:1.3;font-weight: bold;}
.position-details .job-item .location{height:0.88rem;background:url(../img/location.png) no-repeat;background-size:cover;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;margin:0.3rem 0;}
.position-details .job-item .location .txt{font-size:0.32rem;color:rgba(102, 102, 102, 1);padding-left:0.68rem;line-height:1.2;padding-top:0.1rem;}
.position-details .job-item .price{margin:0 0 0.3rem 0;color:#333;font-size:0.24rem;}
.position-details .job-item .price .num{font-size:0.48rem;display: inline-block;margin-right:0.1rem;color:#ff6831;font-weight: bold;}
.position-details .job-item .price .share{color:#ff6600;font-size:0.24rem;}
.position-details .positon-location{position: relative;margin:0.2rem 0;border:1px solid #f0f0f0;}
.position-details .positon-location .img{width:100%;}
.position-details .positon-location .info{position: absolute;bottom:0;left: 0;right: 0;background: rgba(255, 255, 255, 0.8);padding:0.24rem;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;}
.position-details .positon-location .txt{font-size:0.24rem;color:#333;line-height:1.4;-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;position: relative;padding-right:0.3rem;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.position-details .positon-location .txt:after{content:"";width:0.16rem;height:0.24rem;background:url(../img/location_arrow.png) no-repeat;background-size: contain;position: absolute;top:50%;right:0;margin-top:-0.12rem;}
.position-details .positon-location .location-ico{width:0.28rem;height:0.30rem;background:url(../img/location_ico.png) no-repeat;background-size: contain;margin-right:0.24rem;}

/* .position-details .job-tags{} */
.position-details .job-tags .tag{display: flex;line-height:1.5;margin:0.15rem 0;font-weight: 500;color:#333;font-size: 0.28rem;}
.position-details .job-tags .tag .label{display: flex;align-items: center;}
.position-details .job-tags .tag .ico{width:0.36rem;height:0.36rem;margin-right:0.2rem;}
.position-details .job-tags .tag .number{background:url(../img/job-tag1.png) no-repeat;background-size: contain;}
.position-details .job-tags .tag .edu{background:url(../img/job-tag2.png) no-repeat;background-size: contain;}
.position-details .job-tags .tag .type{background:url(../img/job-tag3.png) no-repeat;background-size: contain;}
.position-details .job-tags .tag .other{background:url(../img/job-tag4.png) no-repeat;background-size: contain;}
.position-details .job-tags .text1{flex:1;}

.position-details .ensure{background:#fff9f6;border-top:1px solid #ffede7; display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;padding:0.24rem 0.4rem;margin:0 -0.4rem;margin-top:0.4rem;margin-bottom:-0.4rem;}
.position-details .ensure .ico{width:0.48rem;height:0.48rem;background: url(../img/ensure_ico.png) no-repeat;background-size: contain;margin-right:0.1rem;}
.position-details .ensure p{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;font-size:0.24rem;color:#ff6831;}

.position-details .job-item:first-child{margin-bottom: 0;}
.position-details .job-item .ht{font-size:0.32rem; color:#444;font-weight: bold;margin-bottom:0.3rem;}
/* .position-details .job-item {} */
.position-details .tags{margin-bottom:0;}
.position-details .tags .tag{font-size:0.24rem;border:1px solid #137dff;padding:0.1rem 0.2rem;border-radius:0.1rem;margin:0 0.2rem 0.2rem 0;color:#137dff;display: inline-block;}
.position-details .job-item .textarea{font-size: 0.28rem;color:#333;line-height:1.6;text-align:justify;}

.position-details .job-time .item{display:-webkit-box;display:-webkit-flex;display:flex;margin-bottom:0.2rem;height:0.5067rem;line-height:0.5067rem; align-items: center;font-size: 0.28rem;}
.position-details .job-time .item .ico{width:0.36rem;height:0.36rem;margin-right:0.24rem;}
.position-details .job-time .item .date{background:url(../img/icon_xiangq_time.png) no-repeat;background-size: contain;}
.position-details .job-time .item .time{background:url(../img/icon_xiangq_shijian.png) no-repeat;background-size: contain;}
.position-details .job-time .item .week{background:url(../img/icon_xiangq_zhou.png) no-repeat;background-size: contain;}
.position-details .job-time .item .other{background:url(../img/icon_xiangq_other.png) no-repeat;background-size: contain;}
.position-details .job-time .item .text{color:#333;}

/*职位详情 --- 职位列表*/
.position-details .job-list{margin:-0.2rem 0;}
.position-details .job-list .item{border-bottom:1px solid #ebebeb;/* ebebeb */margin:0 -0.4rem;padding:0.4rem 0.4rem 0.4rem;display:-webkit-box;display:-webkit-flex;display:flex;}
.position-details .job-list .item:last-child{border-bottom: none;}
.position-details .job-list .item .ml{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;}
.position-details .job-list .item .ml .job-title{font-size:0.36rem;color:#333;height:0.8rem;line-height:0.8rem;margin-bottom:0.2rem;}
.position-details .job-list .item .ml .job-tags{height: 0.5rem;line-height:0.5rem;padding:1px 0;}
.position-details .job-list .item .ml .job-tags .job-tag{font-size:0.32rem;color:#666;display: inline-block;border-right:1px solid #666;padding-right:0.2667rem;margin-right:0.2667rem;line-height:1;}
.position-details .job-list .item .ml .job-tags .job-tag:last-child{border:none;}
.position-details .job-list .item .mr{text-align: right;margin-left:0.4rem;}
.position-details .job-list .item .mr .wages{font-size:0.4rem;color:#333;height:0.8rem;line-height:0.8rem;margin-bottom:0.2rem;}
.position-details .job-list .item .mr .wages strong{font-size:0.36rem;font-weight: bold;color:#ff6831;font-weight: bold;font-family:'Tahoma';}
.job-list .item .mr .unit{border:1px solid #ff6831;height:0.5rem;line-height:0.5rem;padding:0 0.16rem;text-align: center;}.position-details {font-size:0.32rem;color:#ff6831;}
/* .position-details .job-list .item{} */

.position-details .foot{text-align: center;padding:0.4rem 0;}
.position-details .foot .f-logo .logo-img{ width:3.52rem;vertical-align: top;padding:0.1rem 0 0.3rem;}
.position-details .foot .copyright{font-size:0.32rem;color:#666;line-height:1.5;}

.position-details .fixed-bottom{position: fixed;height:1.2rem;bottom:0;left:0;right:0;display:-webkit-box;display:-webkit-flex;display:flex;display:-webkit-box;display:-webkit-flex;display:flex;}
.position-details .fixed-bottom-zw{height:2rem;}
.position-details .fixed-bottom .info{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;background:#fff;border-top:1px solid #ebebeb;height:1.2rem;box-sizing: border-box;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;justify-content: space-between;align-items: center;padding:0 0.3rem;}
.position-details .fixed-bottom .info .txt{line-height: 1.5;}
.position-details .fixed-bottom .info .txt .span{font-size:0.24rem;color:#ff6831;}
.position-details .fixed-bottom .info .txt .strong{font-size:0.24rem;color:#666;}
.position-details .fixed-bottom .info .price{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;text-align: right;font-size:0.32rem;color:#666;}
.position-details .fixed-bottom .info .price .num{font-size:0.48rem;color:#ff6831;font-weight: bold;}
.position-details .fixed-bottom .info .price .cny{font-size:0.24rem;}
.position-details .fixed-bottom .btns{width:3.6rem;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;height:1.6rem;}
.position-details .fixed-bottom .btns .btn{background:#137dff;font-size:0.4267rem;color:#fff;font-weight: bold;text-align: center;-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;height:1.6rem;line-height:1.6rem;}
.position-details .fixed-bottom .btns .btn.disabled{background:#d9eaff;font-size:0.4267rem;color:#3992ff;font-weight: bold;text-align: center;-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;height:1.6rem;line-height:1.6rem;border-left:1px solid #3992ff;border-top:1px solid #3992ff;box-sizing:border-box;}
.position-details .job-bottom{position: fixed;height:1.4rem;bottom:0;left:0;right:0;border-top:1px solid #ebebeb;background:#fff;display:-webkit-box;display:-webkit-flex;display:flex;}
.position-details .job-bottom .share{padding:0.1rem 0.8rem 0;}
.position-details .job-bottom .share .ico{width:0.6667rem;height:0.6667rem;background:url(../img/icon_zw_share.png) no-repeat;background-size: contain;margin-bottom:0.1rem;}
.position-details .job-bottom .share .txt{font-size:0.32rem;color:#666;}
.position-details .job-bottom .btns{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;background:#137dff;}
.position-details .job-bottom .btns .btn{color:#fff;font-size:0.36rem;color:#fff;display: block;text-align: center;line-height:1.4rem;}
.position-details .job-bottom-zw{height:1.4rem;}

.position-details .position-share{position: fixed;width:1.2rem;height:1.2rem;top:4.8rem;right:0;-webkit-box-align: center;}
.position-details .position-share .img{width:1.2rem;height:1.2rem;}

.position-details .job-item .tips{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;}
.position-details .job-item .tips .txt{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;font-size:0.24rem;line-height:1.5;color:#ff6831;}
.position-details .job-item .tips .ico{width:0.36rem;height:0.36rem;background:url(../img/tips_ico.png) no-repeat;background-size: contain;margin-right:0.25rem;}
.position-details .job-item .tips .btn{height:0.64rem;padding:0 0.4533rem;background:#fff7f3;color:#ff6927;font-size:0.24rem;border:1px solid #ffb493;padding:0 0.4rem;border-radius:0.32rem;line-height:0.64rem;}

.position-details .share-tag{background:#f3feff;display:-webkit-box;display:-webkit-flex;display:flex;font-size:0.32rem;color:#4cdbd9;-webkit-box-align: center;-webkit-align-items: center;align-items: center;padding:0.3rem;white-space: nowrap;position: fixed;bottom: 1.2rem;width: 100%;height: 0.8rem;;box-sizing: border-box;}
.position-details .share-tag .ico{margin:0 0.2rem 0 0;width:0.42rem;height:0.32rem;background:url(../img/position_msg.png) no-repeat;background-size: contain;}
.position-details .share-tag .txt{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;margin-right:0.2rem;font-size: 0.24rem;}
.position-details .share-tag .btn{text-decoration: underline;font-size: 0.24rem;}
.position-details .report-btn { font-size: 0.24rem; font-family: 'PingFang-SC-Medium'; font-weight: 500; color: rgba(255, 105, 39, 1); background: rgba(255, 211, 191, 1); border: 0.01rem solid rgba(255, 105, 39, 1); opacity: 0.5; border-radius: 0.25rem; padding: 0 0.10rem; width: 1.23rem; height: 0.50rem; text-align: center; line-height: 0.50rem; margin-left: 0.48rem; }

/* 职位详情报名按钮 */
.fixed_bottom_btns{ display: '-webkit-box'; display: '-webkit-flex'; display: flex; align-items: center; height: 1rem; }
.fixed_bottom_btns_btn{ background: #137dff; font-size: 0.4267rem; color: #fff; font-weight: bold; text-align: center; flex: 1; height: 1.6rem; line-height: 1.6rem; }
.fixed_bottom_btns_btn_disabled{ background: #d9eaff; font-size: 0.4267rem; color: #3992ff; font-weight: bold; text-align: center; flex: 1; height: 1.6rem; line-height: 1.6rem; border-left: #3992ff; border-top: #3992ff; box-sizing: border-box; }

.user-center{max-width:10rem;margin:0 auto;}
.user-center .user-top{height:6.08rem;background:url(../img/user_center_top.jpg) no-repeat;background-size: contain;}
.user-center .user-info{height:4.6rem;padding-top: 0.65rem;box-sizing: border-box;text-align: center;}
.user-center .user-info .avatar{position: relative;}
.user-center .user-info .avatar .img{width:1.5rem;height:1.5rem;border-radius:1.5rem;background-color:rgba(255, 255, 255, 0.3);}
.user-center .user-info .avatar .edit{position: absolute;bottom:0;left:0;right:0;text-align: center;}
.user-center .user-info .avatar .edit-btn{background:#58e7f6;display: inline-block;line-height:0.4267rem;border-radius:0.2rem;padding:0 0.16rem;color:#fff;display: none;}
.user-center .user-info .name{font-size:0.32rem;color:#fff;text-align: center;padding-top:0.2rem;}
.user-center .user-items{margin:0 0.4rem;height:2rem;margin-top:-2.5rem;background:#fff;border-radius:0.2667rem;box-shadow:0px 0.04rem 0.2rem 0 rgba(30,104,234,0.1);display:-webkit-box;display:-webkit-flex;display:flex;justify-content:space-between;-webkit-box-align: center;-webkit-align-items: center;align-items: center;padding:0rem 0.4rem 0 0;box-sizing: border-box;}
.user-center .user-items .money{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;padding:0 0.4rem;}
.user-center .user-items .money .item{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;text-align: center;}
.user-center .user-items .money strong{font-size:0.48rem;font-weight: bold;color:#1a1a1a;line-height: 1;margin-bottom:0.3rem;display: block;}
.user-center .user-items .money p{font-size:0.30rem;color:#666;line-height: 1;}
.user-center .user-items .tx{width:1.4rem;height:1.4rem;}
.user-center .user-items .tx-ico{width:1.4rem;height:1.4rem;background:url(../img/tx_ico.jpg) no-repeat;background-size: contain;display: block;}
.user-center .user-center-ad{margin:0.3rem 0.4rem 0;}
.user-center .user-center-ad .ad-img{width:100%;vertical-align: top;display: 'inline-block';height: '79px';touch-action: none;}
.user-center .head-nav{box-shadow:0px 0.04rem 0.2rem 0 rgba(30,104,234,0.1);margin:0.2rem 0.4rem 0.4rem;border-radius:0.2667rem;display:-webkit-box;display:-webkit-flex;display:flex;}
.user-center .head-nav .item{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;text-align: center;padding:0.4rem 0;position: relative;white-space: nowrap;}
.user-center .head-nav .item .tip{position: absolute;text-align: center;top: 0.2rem;width: 100%;}
.user-center .head-nav .item .tip .content{background-color: rgba(255,102,0,0.9);border: 1px solid #fff;padding:0.05rem 0.1rem;color: #fff;font-size: 0.20rem;border-radius: 0.4rem;margin: 0;}
.user-center .head-nav .ico{width: 0.72rem;height: 0.72rem;margin:0 auto;}
.user-center .head-nav .pt{background:url(../img/head_nav_ico_pt.png) no-repeat;background-size: contain;}
.user-center .head-nav .fx{background:url(../img/head_nav_ico_fx.png) no-repeat;background-size: contain;}
.user-center .head-nav .xz{background:url(../img/head_nav_ico_xz.png) no-repeat;background-size: contain;}
.user-center .head-nav .xy{background:url(../img/head_nav_ico_xy.png) no-repeat;background-size: contain;}
.user-center .head-nav .txt{font-size:0.30rem;color:#666;margin-top:0.2rem;}
.user-center .list-nav{box-shadow:0px 0.04rem 0.2rem 0 rgba(30,104,234,0.1);margin:0.4rem 0.4rem 2rem;border-radius:0.2667rem;padding:0 0.4rem;}
.user-center .list-nav .tit{line-height:1rem;padding-top:0.2rem;border-bottom:0.005rem solid #ebebeb;font-size:0.32rem;color:#1a1a1a;}
.user-center .list-nav .item{display:-webkit-box;display:-webkit-flex;display:flex;height:1.2rem;-webkit-box-align: center;-webkit-align-items: center;align-items: center;}
.user-center .list-nav .item .ico{width:0.54rem;height:0.54rem;margin-right:0.3rem;}
.user-center .list-nav .item .wdzl{background:url(../img/list_nav_ico_wdzl.png) no-repeat;background-size: contain;}
.user-center .list-nav .item .smrz{background:url(../img/list_nav_ico_smrz.png) no-repeat;background-size: contain;}
.user-center .list-nav .item .zgqw{background:url(../img/list_nav_ico_zgqw.png) no-repeat;background-size: contain;}
.user-center .list-nav .item .yjfk{background:url(../img/list_nav_ico_yjfk.png) no-repeat;background-size: contain;}
.user-center .list-nav .item .txt{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;font-size:0.32rem;color:#333;position: relative;}
.user-center .list-nav .item .txt:after{position: absolute;width:0.16rem;height:0.2933rem;background:url(../img/yq_arrow.png) no-repeat;background-size: contain;content:"";right:0;top:50%;margin-top:-0.1467rem;}
.user-center .list-nav .items{padding:0.2rem 0;}

.fixed-nav{height:1.2rem;border-top:1px solid #f2f2f2;background:#fff;display:-webkit-box;display:-webkit-flex;display:flex;position: fixed;bottom:0;left: 0;right:0;}
/* .fixed-nav-zw{height:1.44rem;margin-top:1px;} */
.fixed-nav .item{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;text-align: center;}
.fixed-nav .item img{width:0.48rem;height:0.48rem;margin:0 auto;margin-top:0.16rem;}
.fixed-nav .item .ico{width:0.6933rem;height:0.6933rem;margin:0 auto;margin-top:0.16rem;}
.fixed-nav .item .home{background:url(../img/nav_ico_home.png) no-repeat;background-size: contain;}
.fixed-nav .item .msg{background:url(../img/nav_ico_msg.png) no-repeat;background-size: contain;}
.fixed-nav .item .my{background:url(../img/nav_ico_my.png) no-repeat;background-size: contain;}
.fixed-nav .item .positon{background:url(../img/nav_ico_position.png) no-repeat;background-size: contain;}
.fixed-nav .item.cur .positon{background:url(../img/nav_ico_position_cur.png) no-repeat;background-size: contain;}
.fixed-nav .item.cur .home{background:url(../img/nav_ico_home_cur.png) no-repeat;background-size: contain;}
.fixed-nav .item.cur .msg{background:url(../img/nav_ico_msg_cur.png) no-repeat;background-size: contain;}
.fixed-nav .item.cur .my{background:url(../img/nav_ico_my_cur.png) no-repeat;background-size: contain;}
.fixed-nav .item .txt{color:#a4aab2;font-size:0.24rem;line-height:1.6;}
.fixed-nav .item.cur .txt{color:#333;}

/*表单信息编辑*/
.form-wrap{padding:0 0.4rem 0.4rem;}
.form-wrap .am-button{line-height:1rem;background:linear-gradient(90deg,rgba(4,112,245,1),rgba(8,138,249,1));
	box-shadow:0px 0.1067rem 0.24rem 0px rgba(4,112,245,0.36);border-radius:0.5867rem; 
	font-size: 0.32rem;color:#fff;display: block;border:none;width:100%;text-align: center;height: 1rem; }
.form-wrap .item{border-bottom:1px solid #ebebeb;padding:0.3rem 0 0.1rem;}
.form-wrap .item-avatar{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;padding-bottom:0.4rem;}
.form-wrap .item-avatar .mt{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;}
.form-wrap .item .avatar .img{width:1.0667rem;height:1.0667rem;border-radius:50%;vertical-align: middle;}
.form-wrap .item .mt{font-size:0.32rem;color:#666;line-height:1.4em;}
.form-wrap .item .mt span{color:#ff6600;line-height:0.4rem;}
.form-wrap .tip{font-size:0.32rem;color:#ff6600;line-height:1.4em;margin:0.4rem 0 0;}
/* .form-wrap .item .mc{} */
.form-wrap .item .mc .picker,.form-wrap .item .mc .ipt{font-size:0.32rem;color:#666;height:1rem;line-height:1rem;width:100%;}
.form-wrap .item .mc div{display: flex;flex-direction: row;align-items: center;padding: 0;}
.form-wrap .item .mc .input{width: 100%;}
.form-wrap .item .mc1 div{display: flex;flex-direction: row;align-items: center;width: 3rem;height: 3rem;margin: 0.2rem 0;justify-content: center;}
.form-wrap .item .mc .picker{position: relative;display: flex;flex-direction: row;align-items: center;justify-content: space-between;width: 90vw;}
.form-wrap .item .mc .picker:after{content:"";width:0.24rem;height:0.30rem;position: absolute;right:0;background:url(../img/arrow2.png) no-repeat;background-size: contain;}

.form-wrap .btns .btn{line-height:1.1733rem;background:linear-gradient(90deg,rgba(4,112,245,1),rgba(8,138,249,1));
  box-shadow:0px 0.1067rem 0.24rem 0px rgba(4,112,245,0.36);border-radius:0.5867rem; 
  font-size: 0.4267rem;color:#fff;display: block;border:none;width:100%;margin:0.9rem 0 1rem;text-align: center;}
  .form-wrap .item .input-arrow .ipt{padding-right:0.4rem;box-sizing: border-box;}
  .form-wrap .upload-items ul{overflow:hidden;padding:0.2667rem 0;width: 2rem;}
  .form-wrap .upload-items li{width:1.6rem;height:1.6rem;margin-right:0.2667rem;float: left;}
  .form-wrap .upload-items li.file-item{position: relative;}
  .form-wrap .upload-items li.file-item img{max-width:100%; max-height:100%; position: absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%);}
  .form-wrap .upload-items li.upload-ipt{background:url(../img/file_ipt.png) no-repeat;background-size: contain;z-index:1;}
  .form-wrap .upload-items li.upload-ipt .ipt{opacity:0;z-index:10;width:1.6rem;height:1.6rem;}
  .form-wrap .file-tip{padding-top:0.4rem;}
  .form-wrap .file-tip p{font-size:0.28rem;color:#989898;line-height:1.5;}
  .form-wrap .tag-item{display: flex;flex-direction: row;align-content: center;justify-content: space-between;flex-wrap: wrap;}
  .form-wrap .tag-item .tag{display: inline-block;width:47%;border:1px solid #ebebeb;height:0.72rem;border-radius:0.44rem;line-height:0.72rem;font-size:0.28rem;color:#333;text-align: center;box-sizing: border-box;margin: 0.2rem 0.05rem; overflow: hidden;white-space: nowrap;text-overflow: ellipsis;padding: 0 0.3rem;}
  .form-wrap .tag-item .tag.active{border-color:#0470f5;color:#0470f5;padding: 0 0.3rem;}
  .form-wrap .textarea{}
  .form-wrap .textarea .ipt{height:8em;font-size:0.32rem;line-height:1.6;color:#333;height:8em;width: 100%;}
/* 修改账户 */
.jumptoChangeAccount { font-size: 0.24rem; color: rgba(8,138,249,1); align-items: center; display: flex; align-items: center; white-space: nowrap;}
.jumptoChangeAccount .img { width: 0.24rem; height: 0.24rem; margin-left: 0.1rem;}
/*homepage*/
.homepage{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction: column;position: relative;width: 100%;height: 100%;overflow: hidden;}
.homepage .fromindex{/*height: 2.2rem;*/width: 100%;margin: 0;padding: 0;/**height: 100%;*/overflow-y: scroll;display: 'inline-block';}
.homepage .top{display: flex;padding:0.3rem 0.4rem;align-items: center;position: fixed;z-index: 9999;background-color: #fff;width: 100%;box-sizing: border-box;}
.homepage .top .location{padding:0 0.4rem 0 0;display: flex;align-items: center;}
.homepage .top .location-img,.top .search-img{width:0.36rem;height:0.36rem;}
.homepage .top .location-img{background:url(../img/home_location.png) no-repeat;background-size: contain;}
.homepage .top .search-img{background:url(../img/search_ico.png) no-repeat;background-size: contain;}
.homepage .top .location-txt{font-size:0.28rem;color:#333;padding:0 0 0 0.1867rem;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.homepage .top .search{flex: 1;height:0.8rem;background:#F8F9FA;border-radius:0.4267rem;display: flex;padding:0 0.4rem;align-items: center;font-size: 0.24rem;color: #999;}
.homepage .top .search .ipt{flex: 1;margin-right:0.1333rem;background:none;color: #333;font-size: 0.28rem;width: 100%;}
.homepage .top .search .clean{width: 0.7rem;font-size: 0.28rem;}

.homepage .tab{padding:0.4rem 0.4rem 0.5333rem 0.5333rem;display: flex;}
.homepage .tab .item{font-size:0.4267rem;color:#999;font-weight: bold;padding-right:0.8rem;position: relative;line-height:2;padding:0 0.8rem 0.08rem 0;}
.homepage .tab .item.active{font-size:0.36rem;color:#333;margin-top:-0.08rem;}
.homepage .tab .item.active:after{content: "";position: absolute;bottom:0;left:0;width:0.9333rem;height:0.1333rem;background:#1182FF;}

.scrollWrap{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;height: auto;width: 100%;overflow: auto;-webkit-overflow-scrolling:touch;position: relative;}
.homepage .filter{justify-content: space-between;display: flex;/*border-top:0.01rem solid #EBEBEB;border-bottom:0.01rem solid #EBEBEB;*/align-items: center;padding:0.1rem 0.4rem;/*position: fixed;top: 1.4rem;z-index: 9999;*/background-color: #fff;margin: 0.4rem auto 0.3rem;}
.homepage .filter .item{flex: 1;text-align: center;font-size:0.24rem;color:#666;line-height: 0.24rem;}
/* .homepage .filter .item:nth-child(1),.filter .item:nth-child(2){border-right:0.01rem solid #EBEBEB;} */
.homepage .filter .item .bor{width:0.16rem;height:0.16rem;display: inline-block;margin-left:0.2rem;position: relative;top:0;}
.addFind{ display: flex; flex-direction: column;}
.findPosition{flex: 1;text-align: center;font-size:0.34rem;color: #333;font-weight: bold;line-height: 0.58rem;}
.line{width:0.7rem;height: 0.1rem;background: rgba(245,245,245,1);border-radius: 0.15rem;margin-top: 0.02rem;margin-bottom: 0.01rem;}
.homepage .lists{}
.homepage .lists .item{display: flex;border-bottom:0.01rem solid #F8F9FA;}
.homepage .lists .item .ml{flex: 1;}
.homepage .lists .item .ht{display: flex;align-items: center;height:0.5333rem;line-height:0.5333rem;}
.homepage .lists .item .ht .name{margin-right:0.2rem;font-size:0.32rem;font-weight: bold;color:#333;}
.homepage .lists .item .ht .label-ico{margin-right:0.1333rem;vertical-align: middle;display: inline-block;}
.homepage .lists .item .ht .bao-ico{width: 0.36rem;height:0.36rem;background:url(../img/ensure_ico.png) no-repeat;background-size: contain;}
.homepage .lists .item .tags{display: flex;margin-top:0.4rem;height: 0.5rem;align-items: center;}
.homepage .lists .item .tag{font-size:0.24rem;color:#666;border-right:1px solid #ebebeb;padding-right:0.2133rem;margin-right:0.2133rem;white-space: nowrap;}
.homepage .lists .item .tag:last-child{border-right:none;}
.homepage .lists .item .mr .salary{height:0.5333rem;line-height:0.5333rem;}
.homepage .lists .item .mr .salary .money{font-size:0.36rem;font-family: "Arial","Microsoft YaHei","黑体","宋体",sans-serif;font-weight: bold;color:#4B93FF;}
.homepage .lists .item .mr .salary .unit{font-size:0.24rem;margin:0 0.08rem;color:#4B93FF;}
.no-data{border-top:1px solid #E8E8E8;padding:0.5333rem 0;display: flex;align-items: center;justify-content: center;}
.homepage .lists .item .mr .date{margin-top:0.4rem;padding:0;text-align:right;height:0.5333rem;}
.homepage .lists .item .mr .date .txt{line-height:0.54rem;background:#f5f5f5;padding:0 0.1867rem;font-size:0.24rem;color: #999;display: inline-block;vertical-align: top;height: 0.5rem;border-radius: 0.5rem;}

/*首页职位*/
.position-list{padding:0;}
.position-list .item{padding:0.4rem;}
.position-list .item .mt{display:-webkit-box;display:-webkit-flex;display:flex;}
.position-list .item .ht{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;display:-webkit-box;display:-webkit-flex;display:flex;}
.position-list .item .mt .money{font-size:0.40rem;font-weight: bold;color:#FF6600;}
.position-list .item .mt .unit{font-size:0.32rem;margin:0 0.08rem;color:#333;}
.position-list .item .ht .name{overflow: hidden;display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical;text-overflow: ellipsis;margin-right:0.2rem;font-size:0.4rem;color:#333;}
.position-list .item .ht .label{margin-right:0.6rem;}
.position-list .item .ht .label-ico{width:0.5333rem;height:0.5333rem;display: inline-block;}
.position-list .item .ht .bao-ico{background:url(../img/ensure_ico.png) no-repeat;background-size: contain;}
.position-list .item .mc .price{margin-top:0.26rem;}
.position-list .item .mc .money{font-size:0.32rem;font-weight: bold;color:#FF6600;}
.position-list .item .mc .unit{font-size:0.32rem;margin:0 0.08rem;color:#333;}
.position-list .item .mc .price{font-size:0.32rem;color:#ff6600;margin-top:0.28rem}
.position-list .item .mc .price .del{color:#666;}
.position-list .item .mc .price .del .money{color:#666;}
.position-list .item .tags{display: flex;margin-top:0.3rem;-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;}
.position-list .item .tag{font-size:0.32rem;color:#666;border-right:1px solid #999;padding-right:0.2133rem;margin-right:0.2133rem;}
.position-list .item .tag:last-child{border-right: none;}

.homepage .lists .item .tag:last-child{border-right:none;}
.position-list .item .mc{display:-webkit-box;display:-webkit-flex;display:flex;}
.position-list .item .mb{display:-webkit-box;display:-webkit-flex;display:flex;margin-top:0.4rem;-webkit-box-align: center;-webkit-align-items: center;align-items: center;}
.position-list .item .avatar-items{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;}
.position-list .item .avatar-items ul{display:-webkit-box;display:-webkit-flex;display:flex;margin-left:0.24rem;}
.position-list .item .avatar-items li{margin-left:-0.24rem;}
.position-list .item .avatar-items .tip{font-size:0.32rem;color:#666;margin-left:0.24rem;-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;}
.position-list .item .avatar-items li img{width:0.6rem;height:0.6rem;border-radius:50%;border:0.0267rem solid #fff;}
.position-list .item .mb .share-btn{}
.position-list .item .mb .share-btn .btn{line-height:0.8rem;border:1px solid #3a7bff;border-radius:0.1333rem;padding:0 0.4rem;display: inline-block;font-size:0.4rem;color:#3a7bff;font-weight: bold;}
.position-list .item .mb .share-btn .end{font-size:0.32rem;color:#666;}

/* 求职 */
.position1-list{padding:0 0.4rem;}
.position1-list .item{margin:0.4rem 0;border-radius:0.3rem;box-shadow:0 0 0.1333rem rgba(0, 111, 229, 0.1);padding:0.4rem 0.32rem 0.32rem 0.4rem;}
.position1-list .item .mt{display:-webkit-box;display:-webkit-flex;display:flex;}
.position1-list .item .ht{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;display:-webkit-box;display:-webkit-flex;display:flex;}
.position1-list .item .mt .money{font-size:0.32rem;font-weight: bold;color:#4B93FF;}
.position1-list .item .mt .unit{font-size:0.24rem;margin:0 0.08rem;color:#4B93FF;}
.position1-list .item .ht .name{overflow: hidden;display:-webkit-box;-webkit-line-clamp:1;-webkit-box-orient:vertical;text-overflow: ellipsis;margin-right:0.2rem;font-size:0.32rem;font-weight: bold;color:#333;}
.position1-list .item .ht .label{margin-right:0.6rem;}
.position1-list .item .ht .label-ico{width:0.5333rem;height:0.5333rem;display: inline-block;}
.position1-list .item .ht .bao-ico{background:url(../img/ensure_ico.png) no-repeat;background-size: contain;}
.position1-list .item .mc{display: flex;flex-direction: row;align-items: center;}
.position1-list .item .mc .money{font-size:0.24rem;font-weight: bold;color:#FF6600;}
.position1-list .item .mc .unit{font-size:0.24rem;margin:0 0.08rem;color:#999;}
.position1-list .item .mc .price{font-size:0.24rem;color:#ff6600;margin-top:0.4rem}
.position1-list .item .mc .price .del{color:#666;font-size: 0.24rem;}
.position1-list .item .mc .price .del .money{color:#666;}
.position1-list .item .tags{display: flex;margin-top:0.4rem;-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;}
.position1-list .item .tag{font-size:0.24rem;color:#666;border-right:1px solid #ebebeb;padding-right:0.15rem;margin-right:0.15rem;white-space: nowrap;box-sizing: border-box;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;max-width: 1.5rem;}
.position1-list .item .tag:last-child{border: none;margin-right: 0;padding-right: 0;}

.am-list-body::before{height: 0 !important;}
.am-list-body::after{height: 0 !important;}
.am-list-footer{font-size: 0.3rem !important;color: #ccc !important;}

.homepage .lists .item .tag:last-child{border-right:none;}

.position1-list .item .mc{display:-webkit-box;display:-webkit-flex;display:flex;}
.position1-list .item .mb{display:-webkit-box;display:-webkit-flex;display:flex;margin-top:0.4rem;-webkit-box-align: center;-webkit-align-items: center;align-items: center;}
.position1-list .item .avatar-items{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;}
.position1-list .item .avatar-items ul{display:-webkit-box;display:-webkit-flex;display:flex;margin-left:0.24rem;}
.position1-list .item .avatar-items li{margin-left:-0.24rem;}
.position1-list .item .avatar-items .tip{font-size:0.24rem;color:#666;margin-left:0.24rem;-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;}
.position1-list .item .avatar-items li img{width:0.6rem;height:0.6rem;border-radius:50%;border:0.0267rem solid #fff;}
.position1-list .item .mb .share-btn{}
.position1-list .item .mb .share-btn .btn{line-height:0.7rem;border:1px solid #ddd;border-radius:0.7rem;padding:0 0.4rem;display: inline-block;font-size:0.30rem;color:#333;}
.position1-list .item .mb .share-btn .end{font-size:0.32rem;color:#666;}

.position1-list-ms{margin: 0.4rem;  border-radius: 0.3rem;  box-shadow: 0 0 0.1333rem rgba(0, 111, 229, 0.1);  padding: 0.4rem 0.32rem 0.32rem 0.4rem;}
.position1-list-ms .ms-head{display: flex;align-items: center;justify-content: space-between;width: 100%;}
.position1-list-ms .ms-head .btn{ color: white; text-align: center; height: 0.45rem; line-height: 0.45rem; background:linear-gradient(90deg,rgba(4,112,245,1),rgba(8,138,249,1)); box-shadow:0 0.01rem 0.05rem 0 rgba(4,112,245,0.36); border-radius: 0.45rem;  font-size: 0.24rem; width: 1.2rem; margin: 0; }
.position1-list-ms .ms-head .btn1{ color: black; text-align: center; height: 0.45rem; line-height: 0.45rem; background:linear-gradient(90deg,rgb(255, 100, 39),rgb(255, 147, 74)); box-shadow:0 0.01rem 0.05rem 0 rgba(211, 85, 1, 0.36); border-radius: 0.45rem;  font-size: 0.24rem; width: 1.2rem; margin: 0 0 0 0.2rem; }
.position1-list-ms .ms-head .title{width: 59%;margin-right: 0.1rem;overflow: hidden;  text-overflow: ellipsis;  white-space: nowrap;font-weight: bold;}
.position1-list-ms .ms-body{ background-color: #faf9fa; padding: 0.3rem; border-radius: 0.3rem; font-size: 0.28rem; color: #333;margin-top: 0.2rem; }
.position1-list-ms .ms-body .items{ display: flex; align-items: center; justify-content: space-between; }
.position1-list-ms .ms-body .items:not(:last-child){ margin:0 0 0.2rem; }
.position1-list-ms .ms-body .items .item{ display: flex; align-items: flex-start; }
.position1-list-ms .ms-body .items .state{ color: #ff6600; }
.position1-list-ms .ms-body .items .item .title{ width: 1.4rem; text-align: justify; white-space: nowrap; }
.position1-list-ms .ms-body .items .item .content{ display: inline-block; word-wrap: break-word; word-break: break-all; }
.position1-list-ms .ms-body .items .item .img{ width: 0.32rem; height: 0.32rem; margin: 0 0.2rem 0; }

/* 单选框增加取消面试 */
.filterInterview {align-items: center;}
.filterInterview .item1 { color: white; text-align: center; height: 0.45rem; line-height: 0.45rem; background:linear-gradient(90deg,rgb(255, 100, 39),rgb(255, 147, 74)); box-shadow:0 0.01rem 0.05rem 0 rgba(211, 85, 1, 0.36); border-radius: 0.45rem;  font-size: 0.24rem; width: 1.2rem; margin: 0 0 0 0.2rem;  }


.navigation .top-bar{border: none;position: unset;background:rgba(68, 153, 248, 0 );}
.navigation .top-bar .top{background:rgba(68, 153, 248, 0 );}

.navigation .top-bar .top .h1{background:rgba(68, 153, 248, 0 );color: #fff;}





/*分享页面*/
.share-position{}
.share-position .banner .share-img{width:100%; vertical-align: top;}
.share-position .banner .img{width:100%; vertical-align: top;}
.share-position .top{padding:0 0.2667rem;margin-top:-1rem;position: relative;}
.share-position .head-item{width:100%;height:3.6rem;background:url(../img/share_job_bg.png) no-repeat;background-size: contain;font-size:0.42rem;color:#ffc230;font-weight: bold;padding:0.3rem 0.64rem 0 0.64rem;box-sizing: border-box;background-size: 100% 100%;}
.share-position .head-item .item{line-height:1.5;margin:0.14rem 0;display:-webkit-box;display:-webkit-flex;display:flex; overflow: hidden;height:1.5em;font-size: 0.28rem;}
.share-position .head-item .h1{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;}
.share-position .head-item .btn{text-align: center;font-size:0.28rem;font-weight: normal;padding-top:0.1rem;}

.share-position .guanzhu{padding: 0 0.5rem;text-align: center;font-size: 0.3rem;color: #333;line-height: 2;}
.share-position .guanzhu .guanzhu-bg{position: relative;;width: 100%;height: 3.78rem;border-radius: 0.2rem;background-color: rgba(255, 102, 0, 1);background: url(../img/icon_shared_img.png) no-repeat;background-size: contain;box-sizing: border-box;margin-top: 0.2rem;}
.share-position .guanzhu .guanzhu-bg .erweima{background: #fff;width: 2.4rem;height: 2.4rem;position: absolute;top: 0.26rem;right: 0.6rem;}
.share-position .guanzhu .guanzhu-bg .erweima img{width: 2.4rem;height: 2.4rem;}

.share-position .job-list{padding:0 0.5333rem;}
.share-position .job-list .tit{font-size:0.32rem;color:#333;text-align: center;font-weight: bold;}
.share-position .job-list .item{margin:0.8rem 0;padding:1rem 0.5333rem 0.2rem;border:1px solid #666;border-radius:0.1333rem;}
.share-position .job-list .item .txt{font-size:0.28rem;color:#333;line-height:1.5;margin:0.2rem 0;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;}
.share-position .job-list .item .t{font-size:0.32rem;background:url(../img/duitou.png) no-repeat center center;background-size: contain;text-align: center;margin:0 auto;margin-top:-1.25rem;margin-bottom:0.2rem;}
.share-position .foot{text-align: center;padding:0rem 0 0.6rem;font-size:0.28rem;color:#333;}
.share-position .share-user .share-txt{ text-align: center; line-height:0.72rem;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;margin:0.4rem 0.4rem 0;font-size:0.3rem;color:#ff3232;font-weight: bold;}
.share-position .share-user .py{height:0.48rem;width:0.48rem;border-radius:0.36rem;margin:0 0.1rem;}
/* .share-position .share-user{padding-bottom: 0.2rem;} */
.share-position .share-user .btns{text-align: center;padding:0.1rem 0.4rem;}
.share-position .share-user .btn{height:1rem;line-height:1rem;border-radius:0.5rem;background:#ffc231;text-align: center;font-size:0.32rem;color:#333;display: block;}
.share-position .share-user .btns .add{height:0.48rem;width:0.48rem;background:url(../img/go_on.png) no-repeat;background-size: contain;display: inline-block;vertical-align: middle;margin-right:0.3rem;} 
.share-position .share-user .txt{text-align: center;padding:0.4rem 0;font-size: 0.32rem;}
.share-position .share-user .user{padding:0.4rem 1.4rem 0.2rem;display:-webkit-box;display:-webkit-flex;display:flex;}
.share-position .share-user .user li{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;text-align: center;}
.share-position .share-user .user .img{width:1.6267rem;height:1.6267rem;border-radius:0.8133rem;}
.share-position .share-user .user .mc{font-size:0.3733rem;color:#333;padding-top:0.2rem;}
.share-position .share-total{height:3.6rem;margin:0.6rem 0.4rem 0;background:url(../img/share_ls_bg.png) no-repeat;background-size: contain;}
.share-position .share-total .t{text-align: center;font-size:0.32rem;color:#333;font-weight: bold;position: relative;}
.share-position .share-total .items{display:-webkit-box;display:-webkit-flex;display:flex;}
.share-position .share-total .item{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;text-align: center;margin-top: 0.4rem;}
.share-position .share-total .item .mt{font-family:Tahoma;color:#42e7e2;font-size:0.5rem;font-weight: bold;margin:0.2rem 0 0.4rem;}
.share-position .share-total .item .mc{font-size:0.28rem;color:#333;}
.share-position .share-rule{padding:0.2rem 0.4rem;font-size:0.32rem;color:#333;line-height:1.8;}
.share-position .share-rule .t{text-align: center;font-weight: bold;margin-bottom:0.2rem;}
.share-position .share-rule .item{display:-webkit-box;display:-webkit-flex;display:flex;margin-bottom:0.1rem;}
.share-position .share-rule .item .txt{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;font-size: 0.28rem;text-align: justify;}
.share-position .share-rule .item .txt span{color:red;font-size: 0.32rem;}
.share-position .share-rule .item .label{margin-right:0.2rem;}
.share-position .share-rule .item .label span{width:0.45rem;height:0.45rem;display: inline-block;background:url(../img/share_rule_ico.png) no-repeat;background-size: contain;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;line-height:0.5rem;}


.share-position .share-yq-user{margin:0.4rem;border:1px solid #dcba71;border-radius:0.1333rem;padding:0.4rem;}
.share-position .share-yq-user .user-list{margin-top: -0.4rem;}
.share-position .share-yq-user .user-list .img{width:0;height:0;border-radius:0.5rem;}
.share-position .share-yq-user .t{text-align: center;color:#e2c584;font-size:0.32rem;position: relative;top:-0.58rem;width:4rem;margin:0 auto;background:url(../img/duitou2.png) no-repeat center center;background-size: contain;}
.share-position .share-yq-user .user-list .item{display:-webkit-box;display:-webkit-flex;display:flex;padding:0.3rem 0;border-bottom:1px solid #ebebeb;}
.share-position .share-yq-user .user-list .item .info{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;}
.share-position .share-yq-user .user-list .item .info .name{font-size:0.3rem;color:#333;margin: 0 0.4rem 0 0;}
.share-position .share-yq-user .user-list .item .info .tag{padding: 0 0.3rem;height:0.36rem;border-radius:0.24rem;background:#dcbb72;color:#fff;font-size:0.24rem;-webkit-box-align: center;-webkit-align-items: center;align-items: center;display:-webkit-box;display:-webkit-flex;display:flex;}
.share-position .share-yq-user .user-list .item .status{font-size:0.28rem;color:#333;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;}
.share-position .share-yq-user .user-list .btns{text-align: center;padding-top:0.3rem;}
.share-position .share-yq-user .user-list .btn{height:0.72rem;line-height:0.72rem;padding:0 0.4rem;border-radius:0.5rem;font-size:0.32rem;color:#fff;background:#debd76;display: inline-block;}

.share-position .qa{margin:0.4rem;border:1px solid #c3c3c3;border-radius:0.1333rem;padding:0.2rem 0.4rem 0.2rem;}
.share-position .qa .t{text-align: center;color:#333;font-size:0.32rem;position: relative;top:-0.45rem;width:3.6rem;margin:0 auto;background:#fff;font-weight: bold;}
.share-position .qa .item{font-size:0.3rem;margin-bottom:0.4rem;text-align: justify;}
.share-position .qa .ask{color:#333;margin-bottom:0.2rem;}
.share-position .qa .answer{color:#999;}
.share-position .qa .bt{background:#fff;width:3rem;margin:0 auto;position: relative;bottom:-0.4rem;text-align: center;height:0.4rem;}
.share-position .qa .bt i{width:0.08rem;height:0.08rem;border-radius:0.0667rem;background:#999;margin:0 0.1rem;display: inline-block;margin-top: 0.15rem;}
.share-position .banner .img{width: 100%;}
/* 被分享报名成功页 */
.share{width:10rem;margin:0 auto;height:100%;}
.share .bg1{width:100%;}
.share .bg2{position:relative;}
.share .bg2 .banner{height:100%;}
.share .txt{position: absolute;line-height:2;text-align:center;font-size:0.36rem;left: 0; top: 0; right: 0; bottom: 0;margin: auto;}
.share .txt span{color: red;font-weight:bold;}
.share .txt1{position: absolute;line-height:2;text-align:center;font-size:0.36rem;left: 0;right: 0; bottom: 10%;margin: auto;color: red;}
.share .zhiwen{position:absolute;top: 0.5rem; left: 0;width:80%;left: 0; right: 0; bottom: 0;margin: auto;box-sizing: border-box;padding:0 1rem;}
.share .item{position: relative;}
.share .item img{width:100%;vertical-align: top;}

/*活动页面css*/
.register-page{background:#3fbc84;}
.register-page .head{}
.register-page .head img{width:100%;vertical-align: top;}
.register-page .form{padding:0.4rem 0.8rem 0.8rem;}
.register-page .form .item{margin-bottom: 0.5rem;}
.register-page .form .item .ipt{height:1.3333rem;line-height:1.3333rem;background:#fff;border-radius:0.2rem;text-align: center;width:100%;font-size:0.4267rem;color:#333;}
.register-page .form .item .ipt::placeholder{color:#b6b6b6;}
.register-page .form .btn{height:1.3333rem;line-height:1.3333rem;border-radius:0.2rem;background:#21a4ff;box-shadow:0 0.1rem 0.1rem #0088e6;width:100%;font-size:0.36rem;color:#fff;}
.register-page .form{}
.register-page .form{}
.register-page .copyright{background:#169b6e;text-align: center;font-size:0.32rem;color:#177455;line-height:1.5;padding:0.4rem 0;}
.yq-wrap .head{}
.yq-wrap .head img{width:100%;vertical-align: top;}
.yq-wrap .item-fff{background:#fff;border-radius:0.2rem;margin:0.4rem 0.3rem;padding:0 0.3rem;padding-bottom:0.4rem;}
.yq-wrap .item-fff:last-child{margin-bottom:0;}

.yq-wrap .head-info{margin-top:-1.3333rem;position: relative;padding-bottom:0.32rem;}
.yq-wrap .head-info .t{font-size: 0.32rem;color:#333;text-align: center;padding:0.6rem 0 0.3rem;line-height:1;font-weight: bold;}
.yq-wrap .head-info .info{display:-webkit-box;display:-webkit-flex;display:flex;margin:0;}
.yq-wrap .head-info .info .item{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;text-align: center;position: relative;}
/* .yq-wrap .head-info .info .item:after{content:"";height:0.4rem;width:1px;background:#e5e5e5;position: absolute;top:0.25rem;right:0;}
.yq-wrap .head-info .info .item:last-child:after{content:"";width:0;height:0;} */
.yq-wrap .head-info .info .mt{font-size:0.22rem;color:#666;}
.yq-wrap .head-info .info .num{font-size:0.36rem;color:#ff6600;font-weight: bold;padding:0.2rem 0 0.4rem;position: relative;display: flex;align-items: center;justify-content: center;}
.yq-wrap .head-info .info .num .img{width:0.16rem;height:0.2333rem;background:url(../img/yq_arrow.png) no-repeat;background-size: contain;margin-left: 0.15rem;}
/* .yq-wrap .head-info .info .num::after{content:"";width:0.16rem;height:0.2933rem;background:url(../img/yq_arrow.png) no-repeat;background-size: contain;position: absolute;top:25%;right:0.2rem;} */
.yq-wrap .head-info .info .num .txt{position: relative;padding-right:0.3rem;}
.yq-wrap .head-info .info .num .txt:after{content:"";width:0.16rem;height:0.2933rem;background:url(../img/yq_arrow.png) no-repeat;background-size: contain;position: absolute;top:50%;right:0rem;margin-top: -0.34rem;}
.yq-wrap .rules{}
.yq-wrap .rules-img{width: 93%;margin: 0 0.3rem;border-radius: 0.2rem;}
.yq-wrap .rules .t{font-size:0.32rem;font-weight: bold;color:#333;text-align: center;padding:0.4rem 0 0.1rem;}
.yq-wrap .rules .t span{color:#34e1b6;display: inline-block;vertical-align: middle}
.yq-wrap .rules .t strong{display: inline-block;margin:0 0.2rem;}
.yq-wrap .rules .tittle{display: flex;justify-content: space-between;align-items: center;padding: 0.2rem 0;}
.yq-wrap .rules span{font-size: 0.3rem;color: #333;font-weight: bold;}
.yq-wrap .rules .btn{border: 1px solid #ff3232;padding: 0.1rem 0.3rem;font-size: 0.24rem;color: #ff3232;border-radius: 1rem;}
.yq-wrap .rules .btn1{border: 1px solid #b849ee;padding: 0.1rem 0.3rem;font-size: 0.24rem;color: #b849ee;border-radius: 1rem;}
.yq-wrap .rules .img img{width:100%;vertical-align: top;}
.yq-wrap .rules .items{display:-webkit-box;display:-webkit-flex;display:flex;}
.yq-wrap .rules .item{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;text-align: center;font-size:0.28rem;line-height:1.4;padding-top:0.3rem;margin-bottom:0.4rem;}
.yq-wrap .rules .item:nth-child(1){margin-left:-0.2rem;color:#b849ee;}
.yq-wrap .rules .item:nth-child(2){color:#aad526;}
.yq-wrap .rules .item:nth-child(3){color:#fe1e5a;}
.yq-wrap .rules .item:nth-child(4){color:#46b6d4;}
.yq-wrap .rules .wf2{}
.yq-wrap .rules .wf2 .item:nth-child(1){text-align:left;margin-left: 0.1rem;}
.yq-wrap .rules .wf2 .item:nth-child(2){color:#fe1e5a;}
.yq-wrap .rules .wf2 .item:nth-child(3){color:#46b6d4;text-align: right;}
.yq-wrap .rules .jj{margin:0.4rem 0;}
.yq-wrap .rules .jj img{width:100%;}
.yq-wrap .rule-cont{padding:0.4rem 0.2rem;border-radius:0.1333rem;font-size: 0.28rem;line-height: 1.5;color: #333;background-color: #f8f9fa;margin-top: 0.4rem;}
.yq-wrap .rule-cont h6{font-size: 0.32rem;text-align: center;padding-bottom: 0.2rem;color: #333;}
.yq-wrap .rule-cont p{font-size: 0.3rem;line-height: 1.5;margin-top: 0.1rem;text-align: justify;}
.yq-wrap .rule-cont p span{font-size: 0.32rem;color: red;}
.yq-wrap .rule-cont h3{text-align: center;font-size:0.4rem;color:#666;font-weight: normal;margin-bottom:0.2rem;}
.yq-wrap .rule-cont .txt{display:-webkit-box;display:-webkit-flex;display:flex;font-size:0.32rem;color:#666;line-height:1.5;margin-bottom:0.1rem;}
.yq-wrap .rule-cont .txt label{margin-right:0.1rem;}
.yq-wrap .rule-cont .txt p{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;}
.yq-wrap .btns{margin:0.4rem 0 0.2rem;}
.yq-wrap .btns .btn{height:1rem;border-radius:0.64rem;background:#ffdd00;font-size:0.32rem;color:#333;text-align: center;width:100%;display: block;line-height:1rem; box-sizing: border-box;border: none;padding: 0;}
.yq-wrap .btns .btn2{background:#4deae5;}
.slider1{background: #fff;overflow: hidden;height: 1.2rem !important;line-height: 1.2rem;margin: 0 0.4rem;font-size: 0.24rem;width: auto !important;border-radius: 0.1rem;}
.slider1 img{width: 0.54rem;height: 0.54rem;border-radius: 0.48rem;}
.slider1 ul li div{display: flex;flex-direction: row;align-items: center;justify-content: space-between;width: 100%;}
.slider1 .slide1{display: flex;flex-direction: row;align-items: center;justify-content: space-between;padding: 0 0.2rem;}

.slider3{display: flex;flex-direction: column;align-items: center;justify-content: space-between;border-width:1;width:100vw;height:'10%';}
.slider3 img{display: flex;flex-direction: column;align-items: center;justify-content: space-between;width: 90%;touch-action: none;}
.am-list-header{padding: 0 !important;overflow-y: unset;padding-top: 1.6rem !important;}
.am-list{width: 100vw;}
.homepage .am-list-view-scrollview{height: 91vh !important;}

.yq-wrap .yq-list{height:1.8rem;overflow: hidden;padding-bottom:0;}
.yq-wrap .yq-list li{height:1.8rem;line-height:1.8rem;font-size:0.3467rem;color:#666;}
.yq-wrap .yq-list li img{width:0.8rem;height:0.8rem;vertical-align: middle;border-radius:50%;margin-right:0.32rem;}
.yq-wrap .yq-list li .name{margin-right:0.32rem;}
.yq-wrap .yq-list li .tag{margin-left:0.32rem;}
.yq-wrap .yq-list li i{color:#ff6600;}
.yq-wrap .how-do .t{text-align: center;padding:0.56rem 0 0.4rem;}
.yq-wrap .how-do .t span{color:#6edbff;font-size:0.4267rem;}
.yq-wrap .how-do .t strong{font-size:0.4267rem;color:#333;display: inline-block;margin:0 0.2rem;}
.yq-wrap .how-do .step{position: relative;}
.yq-wrap .how-do .step img{width:100%;vertical-align:top;}
.yq-wrap .how-do .step .p{position: absolute;font-size:0.3733rem;color:#ff8d3a;line-height:1.5;display:-webkit-box;display:-webkit-flex;display:flex;}
.yq-wrap .how-do .step span{margin-right:0.1rem;}
.yq-wrap .how-do .step p{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;}
.yq-wrap .how-do .step1 .p{top:0.5rem;right:0.8rem;width:3.7rem;}
.yq-wrap .how-do .step2 .p{left:0.8rem;top:0.8rem;width:4.0rem}
.yq-wrap .how-do .step3 .p{right:0.6rem;top:0.7rem;width:4.0rem}

.yq-wrap .how-go .t{width:4.7733rem;height:0.36rem;background:url(../img/howgo_t.png) no-repeat;background-size: contain;margin:1.2rem auto 0.6rem;font-size:0.4267rem;color:#333;line-height: 1;text-align: center;}
.yq-wrap .how-go .step{position: relative;}
.yq-wrap .how-go .step img{width:100%;vertical-align:top;}
.yq-wrap .how-go .step .p{position: absolute;font-size:0.3733rem;color:#4deae5;line-height:1.5;display:-webkit-box;display:-webkit-flex;display:flex;}
.yq-wrap .how-go .step span{margin-right:0.1rem;}
.yq-wrap .how-go .step p{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;}
.yq-wrap .how-go .step1 .p{top:0.5rem;left:0.2rem;width:4.2rem;}
.yq-wrap .how-go .step2 .p{right:0.2rem;top:0.4rem;width:4.0rem}
.yq-wrap .how-do{margin:0 -0.56rem;}
.yq-wrap .foot{text-align: center;padding:0.4rem 0;font-size:0.24rem;color:#999;margin-top:-0.4rem;}
/**/
.yq-wrap .filter{padding:0.4rem;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;}
.yq-wrap .filter .data{background:#f8f9fa;border-radius:0.36rem;padding:0 0.4rem;height:0.8rem;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;}
.yq-wrap .filter .data .arrow{width: 0; height: 0; border-width:0.14rem;border-style: solid;border-color:#abb0b6 transparent transparent transparent;position: relative;top:0.1rem;margin-left:0.2rem;}
.yq-wrap .yq-table{padding:0.4rem;font-size: 0.32rem;background: #fff;border-radius: 0.1rem;margin: 0.4rem;color: #333;}
.yq-wrap .yq-table h6{font-size: 0.32rem;text-align: center;margin-bottom: 0.3rem;}
.yq-wrap .yq-table .more{font-size: 0.3rem;text-align: center;margin-top: 0.3rem;}
.yq-wrap .yq-table .more{font-size: 0.3rem;text-align: center;margin-top: 0.3rem;}
.yq-wrap .yq-table .more a{color: #bcbcbc;font-size: 0.24rem;}
.yq-wrap .yq-table .more a:visited{color: #bcbcbc;}
.yq-wrap .yq-table	table{width:100%;border-collapse: collapse;}
.yq-wrap .yq-table td{border-top:1px solid #ebebeb;border-bottom:1px solid #ebebeb;padding:0.3rem 0.1rem;font-size:0.32rem;color:#666;font-weight: bold;}
.yq-wrap .yq-table .time,.yq-wrap .yq-table .status{color:#666;font-weight: normal;}
.yq-wrap .yq-table .name,.yq-wrap .yq-table .num{margin-bottom:0.1rem;}
.yq-wrap .yq-table td:nth-child(3){text-align:right;}
.yq-wrap .yq-table td:nth-child(2){vertical-align: top;}


/*实名状态*/
.real-name-status{}
.real-name-status .state-info{text-align: center;padding:2.1333rem 0 0;}
.real-name-status .state-info .ico{}
.real-name-status .state-info .ico .ico-img{width: 4.1333rem;height:4.6667rem;}
.real-name-status .state-info .txt{font-size: 0.36rem;color:#333;font-weight: bold;padding:0.2667rem 0 0.5333rem;}
.real-name-status .state-info .tips{font-size:0.3733rem;color:#666;padding-bottom:0.4rem;}
.real-name-status .state-info .btns{padding:1.3333rem 0.5333rem 0.5333rem;}
.real-name-status .state-info .btn{width:100%;height:1rem;line-height:1rem;text-align:center;width:100%;background:linear-gradient(90deg,rgba(4,112,245,1),rgba(8,138,249,1)); box-shadow:0px 0.1067rem 0.24rem 0px rgba(4,112,245,0.36); border-radius:0.64rem;font-size:0.32rem;color:#fff;width:100%;display: block;}
.real-name-status .state-info .text-left{ text-align: left;padding-left:1.4667rem;}
.top-bar{height:1rem;border-bottom:1px solid #ebebeb;background:#fff;position: fixed;top:0;left:0;right:0;z-index: 999;}
.top-bar .top{height:1rem;position: relative;}
.top-bar .goback{position: absolute;top:0;left: 0;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;height:1rem;z-index: 10;padding:0 0.3rem;}
.top-bar .goback .ico{width:0.3rem;height:0.4rem;background:url(../img/goback_ico.png) no-repeat;background-size: contain;}
.top-bar .h1{position: absolute;left:0;right:0;height:1rem;line-height:1rem;text-align: center;font-size:0.36rem;color:#242424;font-weight: bold;background:#fff;z-index: 9;}
.top-bar-zw{height:1rem;}

.tixian{height: 100%;}
.tixian .notice{background:#ffefe4;font-size:0.24rem; color:#ff6831; line-height:1.5;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;padding:0.2rem 0.4rem;}
.tixian .notice a{height:0.56rem;line-height:0.56rem;border-radius: 0.56rem;padding:0 0.15rem;border:1px solid #ff6831;display: inline-block;margin-left:0.2rem;color:#ff6831;}
.tixian .tixian-form{background:#fff;border-radius:0.2rem;padding:0.4rem;box-shadow: 0px 0.04rem 0.2rem 0 rgba(30,104,234,0.15);margin: 0.4rem;}
.tixian .tixian-form .item{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;padding-bottom:0.4rem;}
.tixian .tixian-form .cont{display:-webkit-box;display:-webkit-flex;display:flex;}
.tixian .tixian-form .label{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;font-size:0.28rem;color:#333;}
.tixian .tixian-form .tab-item{height:0.72rem;line-height:0.72rem;border-radius:0.36rem;border:1px solid #ebebeb;font-size:0.28rem;color:#333;padding:0 0.4rem;margin-left:0.2667rem;}
.tixian .tixian-form .tab-item.active{background:#2199ff;border-color:#2199ff;color:#fff;}
.tixian .tixian-form .ipt{width:3.2rem;border-bottom:1px solid #ebebeb;padding:0rem 0;font-size:0.32rem;height:0.6rem;color:#333;text-align: right;padding-right:0.1rem;}
.tixian .tixian-form .ipt::placeholder{color:#666;font-size:0.32rem;}
.tixian .tixian-form .other{display: block;border-bottom:1px solid #ebebeb;}
.tixian .tixian-form .other .mt{}
.tixian .tixian-form .other .mc{display:-webkit-box;display:-webkit-flex;display:flex;padding-top:0.3rem;overflow: hidden;}
.tixian .tixian-form .num{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;}
.tixian .tixian-form .cny{font-size:0.32rem;padding-top:0.1rem;}
.tixian .tixian-form .num-ipt{font-size:1rem;color:#333;font-weight: bold;-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;}
.tixian .tixian-form .am-button{line-height:1rem;background:linear-gradient(90deg,rgba(4,112,245,1),rgba(8,138,249,1));
	box-shadow:0px 0.1067rem 0.24rem 0px rgba(4,112,245,0.36);border-radius:0.5867rem; 
	font-size: 0.32rem;color:#fff;display: block;border:none;width:100%;text-align: center;height: 1rem; }
.tixian .tip{margin:0.4rem;font-size:0.24rem;color:#666;}
.tixian .status-info{}
.tixian .status-info .infos{padding:1.5rem 0;}
.tixian .status-info .ico{width:2.5rem;height:2.5rem;background:url(../img/tx_status.png) no-repeat;background-size: contain;margin: 0 auto;border-radius: 2.5rem;}
.tixian .status-info .txt{text-align: center;padding:0.4rem 0.8rem;}
.tixian .status-info .h{font-size:0.4267rem;color:#333;padding-bottom:0.2rem;font-weight: bold;}
.tixian .status-info .p{font-size:0.28rem;color:#666;line-height: 2;}
.tixian .status-info .btns{display:-webkit-box;display:-webkit-flex;display:flex;padding:0 0.4rem;padding-bottom:2rem;}
.tixian .status-info .btn{height: 1rem;line-height:1rem;border-radius:0.5867rem;-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;margin:0 0.1333rem;text-align: center;border:1px solid #333;font-size:0.32rem;}
.tixian .status-info .btn1{border-color:#0572f5;color:#0572f5;}
.tixian .status-info .btn2{background:#0572f5;color:#fff;border-color:#0572f5;}


/*钱包 wallet*/
.my-wallet .wallet-top{height:3.5rem;background:url(../img/wallet_bg.png) no-repeat;background-size: cover;padding:0.2rem 0;box-sizing: border-box;}
.my-wallet .top{height:1rem;position: relative;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;}
.my-wallet .top .goback{width:0.48rem;height:0.48rem;background:url(../img/goback_ico_white.png) no-repeat;background-size: contain;margin:0 0.3rem;}
.my-wallet .top .h1{position: absolute;height:1rem;line-height:1rem;text-align: center;top:0;left:0;right:0;font-size:0.36rem;color:#fff;font-weight: bold;}
.my-wallet .wallet-top .infos{display:-webkit-box;display:-webkit-flex;display:flex;padding:0.2rem 0.4rem;}
.my-wallet .wallet-top .infos .item{padding:0 0.2rem;line-height: 2}
.my-wallet .wallet-top .infos .item .mt{font-size:0.32rem;color:#fff;white-space: nowrap;}
.my-wallet .wallet-top .infos .item .mc{font-family:Tahoma;font-size:0.36rem;color:#fff;font-weight: bold;}
.my-wallet .wallet-top .infos .btns{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;text-align: center;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:flex-end;}
.my-wallet .wallet-top .infos .btn{height:0.8rem;line-height:0.8rem;border:1px solid #a1d2f9;border-radius:0.4rem; padding:0 0.4rem;font-size:0.32rem;color:#fff;font-weight: bold;}
.my-wallet .main-head{padding:0 0.4rem;}
.my-wallet .main-head .title{padding:0.4rem 0 0.4rem;font-size:0.32rem;color:#1a1a1a;font-weight: bold;}
.my-wallet .main-head .items{display:-webkit-box;display:-webkit-flex;display:flex;justify-content: space-between;}
.my-wallet .main-head .items .item{display: flex;flex-direction: row;align-items: center;justify-content: space-between;margin:0 0.2rem;height:0.72rem;width: 1.5rem; line-height:0.72rem; border-radius:0.36rem;background:#f8f9fa;padding:0 0.32rem;-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;text-align: center;font-size:0.28rem;color:#333;}
.my-wallet .main-head .items .item:first-child{margin-left:0;}
.my-wallet .main-head .items .item:last-child{margin-right:0;}
.my-wallet .main-head .item .arrow{width:0;height:0;border-top:0.14rem solid #abb0b6;border-left:0.14rem solid transparent;border-right: 0.14rem solid transparent;position: relative;}
.my-wallet .main-head .total-infos{}
.my-wallet .main-head{}
.my-wallet .total-infos{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;padding:0.35rem 0;font-size:0.3733rem;color:#ff6600;border-bottom:1px solid #ebebeb;}
.my-wallet .total-infos .txt{font-size:0.28rem;color:#333;font-weight: bold;}
.my-wallet .total-infos .txt.df{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;}
.my-wallet .total-infos .color-orange{color:#ff6600;}
.my-wallet .list-items{padding:0 0.4rem;}
.my-wallet .list-items .item{border-bottom:1px solid #ebebeb;padding:0.2rem 0;}
.my-wallet .list-items .item .mt{margin-bottom:0.2rem;font-size:0.28rem;color:#666;}
.my-wallet .list-items .item .mb{font-size:0.32rem;color:#666;}
.my-wallet .list-items .item .mt,.my-wallet .list-items .item .mb{display:-webkit-box;display:-webkit-flex;display:flex;}
/* .my-wallet .list-items .item .txt,.my-wallet .list-items .item .label{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;} */
.my-wallet .list-items .item .txt{font-size:0.28rem;color:#333;font-weight: bold;}
.my-wallet .list-items .item .txt.add{color:#ff6600;}
.my-wallet .list-items .item .label{font-size:0.28rem;color:#666;}
.my-wallet .list-items .item .txt:last-child,.my-wallet .list-items .item .label:last-child{text-align:right;}

/*工作期望*/
.h100,.word-expect{width: 100%;height: 100%;}
.word-expect .isChoosed{height: 1.5rem;display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #ebebeb;width: 100%;position: fixed;}
.word-expect .tags{display: flex;margin-left: 0.5333rem;}
.word-expect .chooseTag{max-width: 2.1333rem;border: 1px solid rgba(255, 108, 57, 1);border-radius:0.1333rem;font-size:0.3rem;font-family: PingFang-SC-Medium;color: #ff6c39;margin-right:0.2667rem;padding:0.2133rem 0.1333rem;overflow: hidden;text-overflow: ellipsis;word-break: break-all;white-space: nowrap;}
.word-expect .tagNum{color: #444;font-size: 0.3rem;font-family: DIN-Medium;font-weight:bold;text-align: center;align-items: center;margin-right: 0.68rem;}
.word-expect .itemC{margin-left: 0.5333rem;margin-right:0.5333rem;display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #ebebeb;line-height: 1.5rem;height: 1rem;}
/* .word-expect .tagName{color: #444;font-size: 0.3rem;font-family: PingFang-SC-Medium;font-weight: bold;height: 1.1rem;line-height: 1.1rem;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;text-overflow: ellipsis;} */
.word-expect .tagName{color: #444;font-size: 0.3rem;font-family: PingFang-SC-Medium;font-weight: bold;height: 22px;line-height: 0.4rem;overflow: hidden;overflow-y: auto;display: block;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;text-overflow: ellipsis;}
.word-expect .item-hover .ico{width: 0.36rem;height: 0.36rem;margin-right: 0.2667rem;background:url(../img/icon_xuanze.png) no-repeat;background-size: contain;}
.word-expect .saveBtn{color: white;text-align: center;margin-right:0.4267rem;margin-left: 0.4267rem;margin-top: 0.6267rem;margin-bottom: 0.6267rem;height: 1.1733rem;line-height: 1.1733rem;background: linear-gradient(90deg, rgba(4, 112, 245, 1), rgba(8, 138, 249, 1));box-shadow: 0px 8px 18px 0px rgba(4, 112, 245, 0.36);border-radius: 0.5867rem;font-size: 0.4267rem;}
.word-expect .view{height: 100%;width: 100%;}
.word-expect .scrollview{display: -webkit-box;display: -webkit-flex;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-webkit-flex-direction: column;flex-direction: column;width: 100%;height: 7.5rem;}
.word-expect .scroll-wrap{position: absolute;top: 1.5rem;width: 100%;height: 6rem;overflow: auto;}
/* .word-expect .areas-wrap{display: -webkit-box;display: -webkit-flex;display: flex;position: absolute;top: 1rem;right: 0;bottom: 0;left: 0;} */
.word-expect .areas-wrap .areas-box{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;height: 100%;overflow: hidden;border-right: 1px solid #edeae6;}
.word-expect .areas-wrap .areas-box:last-child{border-right: none;}
.areas-wrap .areas-box:nth-child(1){}
.word-expect .areas-wrap .areas-box:nth-child(2){background: #f7f7f7;}
.word-expect .area-item{ -webkit-box-flex: 1;-webkit-flex: 1;flex: 1;height: 100%;overflow: hidden;}
.word-expect .areas-items{display: -webkit-box;display: -webkit-flex;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-webkit-flex-direction: column;flex-direction: column;height: 100%;font-size: 0.28rem;box-sizing: border-box;}
.word-expect .areas-items .items-list{-webkit-box-flex: 1;-webkit-flex: 1;height: auto;overflow: hidden;box-sizing: border-box;}
.word-expect .first-nav .item{margin-left: 0;margin-right: 0;padding: 0 0.5333rem;border-bottom: 1px solid #ebebeb;position: relative;display: block;}
.word-expect .first-nav .item .num{font-size: 0.2667rem;color: #fff;background: #ff6c39;height: 0.4267rem;width: 0.4267rem;border-radius:0.2133rem;text-align: center;line-height: 0.4267rem;display: block;position: absolute;top: 50%;right: 0.4rem;margin-top: -0.16rem;}
.word-expect .first-nav .item-hover{background: #f7f7f7;}
.word-expect .tags1{display: flex;margin-left: 0.5333rem;margin-top:0.4267rem;margin-bottom: 0.4267rem;font-size:0.3rem;font-family: PingFang-SC-Medium;}

/*首页定位*/
.h100,.address{width: 100%;height: 100%;}
.address .isChoosed{height: 1rem;display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #f0f1fa;width: 100%;position: fixed;}
.address .tags{display: flex;margin-left: 0.5333rem;}
.address .chooseTag{max-width: 2.1333rem;border: 1px solid rgba(255, 108, 57, 1);border-radius:0.1333rem;font-size:0.3rem;font-family: PingFang-SC-Medium;color: #ff6c39;margin-right:0.2667rem;padding:0.2133rem 0.1333rem;overflow: hidden;text-overflow: ellipsis;word-break: break-all;white-space: nowrap;}
.address .tagNum{color: #444;font-size: 0.3rem;font-family: DIN-Medium;font-weight:bold;text-align: center;align-items: center;margin-right: 0.68rem;}
.address .itemC{padding: 0 0.5333rem;display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #f0f1fa;border-left: 1px solid #f0f1fa;line-height: 1.5rem;height: 1rem;}
/* .address .tagName{color: #444;font-size: 0.3rem;font-family: PingFang-SC-Medium;font-weight: bold;height: 1.1rem;line-height: 1.1rem;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;text-overflow: ellipsis;} */
.address .tagName{color: #444;font-size: 0.3rem;font-family: PingFang-SC-Medium;font-weight: bold;height: 22px;line-height: 1.5;overflow: hidden;overflow-y: auto;display: block;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;text-overflow: ellipsis;}
.address .item-hover .ico{width: 0.36rem;height: 0.36rem;margin-right: 0.2667rem;background:url(../img/icon_xuanze.png) no-repeat;background-size: contain;}
.address .saveBtn{color: white;text-align: center;margin-right:0.4267rem;margin-left: 0.4267rem;margin-top: 0.6267rem;margin-bottom: 0.6267rem;height: 1.1733rem;line-height: 1.1733rem;background: linear-gradient(90deg, rgba(4, 112, 245, 1), rgba(8, 138, 249, 1));box-shadow: 0px 8px 18px 0px rgba(4, 112, 245, 0.36);border-radius: 0.5867rem;font-size: 0.4267rem;}
.address .view{height: 100%;width: 100%;}
.address .scrollview{display: -webkit-box;display: -webkit-flex;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-webkit-flex-direction: column;flex-direction: column;width: 100%;height: 7.5rem;}
.address .scroll-wrap{position: absolute;top: 1.03rem;width: 100%;height: 6.5rem;overflow: auto;background-color: #fff;}
.address .scroll-wrap .areas-wrap{display: flex;}
/* .address .areas-wrap{display: -webkit-box;display: -webkit-flex;display: flex;position: absolute;top: 1rem;right: 0;bottom: 0;left: 0;} */
.address .areas-wrap .areas-box{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;height: 100%;overflow: hidden;}
.address .areas-wrap .areas-box:last-child{border-right: none;}
.areas-wrap .areas-box:nth-child(1){}
/* .address .areas-wrap .areas-box:nth-child(2){background: #f7f7f7;} */
.address .area-item{ -webkit-box-flex: 1;-webkit-flex: 1;flex: 1;height: 100%;overflow: hidden;}
.address .areas-items{display: -webkit-box;display: -webkit-flex;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-webkit-flex-direction: column;flex-direction: column;height: 100%;font-size: 0.28rem;box-sizing: border-box;background-color: #fff;}
.address .areas-items .items-list{-webkit-box-flex: 1;-webkit-flex: 1;height: auto;overflow: hidden;box-sizing: border-box;}
.address .first-nav .item{margin-left: 0;margin-right: 0;padding: 0 0.5333rem;border-bottom: 1px solid #f0f1fa;position: relative;display: block;}
.address .first-nav .item .num{font-size: 0.2667rem;color: #fff;background: #ff6c39;height: 0.4267rem;width: 0.4267rem;border-radius:0.2133rem;text-align: center;line-height: 0.4267rem;display: block;position: absolute;top: 50%;right: 0.4rem;margin-top: -0.16rem;}
.address .first-nav .item-hover{display: flex;}
.address .tags1{display: flex;margin-left: 0.5333rem;margin-top:0.4267rem;margin-bottom: 0.4267rem;font-size:0.3rem;font-family: PingFang-SC-Medium;}


/*邀请明细 yqmingxi*/
.my-wallet1 .wallet-top{height:2.2rem;background:url(../img/wallet_bg.png) no-repeat;background-size: cover;padding:0.2rem 0;box-sizing: border-box;}
.my-wallet1 .top{height:1.1733rem;position: relative;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;}
.my-wallet1 .top .goback{width:0.6933rem;height:0.6933rem;background:url(../img/goback_ico_white.png) no-repeat;background-size: contain;margin:0 0.32rem;}
.my-wallet1 .top .h1{position: absolute;height:1rem;line-height:1rem;text-align: center;top:0;left:0;right:0;font-size:0.36rem;color:#fff;font-weight: bold;}
.my-wallet1 .wallet-top .infos{display:-webkit-box;display:-webkit-flex;display:flex;padding:0.2rem 0.3rem 0;justify-content: space-around;}
.my-wallet1 .wallet-top .infos .item{line-height: 2;text-align: center;}
.my-wallet1 .wallet-top .infos .item .mt{font-size:0.24rem;color:#fff;white-space: nowrap;}
.my-wallet1 .wallet-top .infos .item .mc{font-family:Tahoma;font-size:0.4rem;color:#fff;}
.my-wallet1 .wallet-top .infos .btns{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;text-align: center;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;}
.my-wallet1 .wallet-top .infos .btn{height:0.8rem;line-height:0.8rem;border:0.0267rem solid #a1d2f9;border-radius:0.4rem; padding:0 0.4267rem;font-size:0.3733rem;color:#fff;font-weight: bold;}
.my-wallet1 .main-head{padding:0 0.2rem;display: flex;flex-direction: row;}
.my-wallet1 .main-head .title{padding:0.4rem 0.2rem 0.4rem;font-size:0.30rem;color:#999;font-weight: bold;}
.my-wallet1 .main-head .title-s{padding:0.4rem 0.2rem 0.4rem;font-size:0.30rem;color:#1a1a1a;font-weight: bold;}
.my-wallet1 .main-head .items{display:-webkit-box;display:-webkit-flex;display:flex;}
.my-wallet1 .main-head .items .item{margin:0 0.2rem;height:0.72rem; line-height:0.72rem; border-radius:0.48rem;background:#f8f9fa;padding:0 0.32rem;-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;text-align: center;font-size:0.32rem;color:#333;}
.my-wallet1 .main-head .items .item:first-child{margin-left:0;}
.my-wallet1 .main-head .items .item:last-child{margin-right:0;}
.my-wallet1 .main-head .item .arrow{width:0;height:0;border-top:0.2rem solid #abb0b6;border-left:0.2rem solid transparent;border-right: 0.2rem solid transparent;position: relative;top:0.3rem;margin-left:0.16rem;}
.my-wallet1 .main-head .total-infos{}
.my-wallet1 .main-head{}
.my-wallet1 .main-head{}
.my-wallet1 .total-infos{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;padding:0 0.53rem 0.2rem;font-size:0.3733rem;color:#ff6600;border-bottom:1px solid #ebebeb;}
.my-wallet1 .total-infos .txt{font-size:0.30rem;color:#333;}
.my-wallet1 .total-infos .txt.df{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;}
.my-wallet1 .total-infos .color-orange{color:#ff6600;}
.my-wallet1 .list-items{padding:0 0.4rem;}
.my-wallet1 .list-items .item{border-bottom:1px solid #ebebeb;padding:0.2rem 0 0.1rem 0;border-bottom:0.02rem solid #f0f0f0;}
.my-wallet1 .list-items .item .mt{margin-bottom:0.2rem;font-size:0.32rem;color:#666;display: flex;justify-content: space-between;align-items: center;}
.my-wallet1 .list-items .item .mb{font-size:0.32rem;color:#666;display: flex;justify-content: space-between;}
.my-wallet1 .list-items .item .mt,.my-wallet .list-items .item .mb{display:-webkit-box;display:-webkit-flex;display:flex;}
/* .my-wallet1 .list-items .item .txt,.my-wallet .list-items .item .label{-webkit-box-flex: 1;-webkit-flex: 1;flex: 1;} */
.my-wallet1 .list-items .item .txt{font-size:0.30rem;color:#333;}
.my-wallet1 .list-items .item .name{font-size:0.30rem;color:#333;width: 30%;}
.my-wallet1 .list-items .item .add{color:#ff6600;}
.my-wallet1 .list-items .item .txt.add{color:#ff6600;width: 30%;}
.my-wallet1 .list-items .item .label{font-size:0.3rem;color:#333;}
.my-wallet1 .list-items .item .txt:last-child,.my-wallet .list-items .item .label:last-child{text-align:right;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;margin-left: 0.4rem;}

/*搜索*/
.search{padding:0.4rem;}
.search .search-ipt{display: flex;flex-direction: row;align-items: center;justify-content: space-between;}
.search .search-ipt .ipt{background: #f0f0f0;width: 60%;border-radius: 0.36rem;height: 0.72rem;}
.search .search-ipt .ipt input{padding-left: 0.3rem; background: #f0f0f0;width: 100%;border-radius: 0.36rem;height: 0.72rem;line-height: 0.72rem;box-sizing: border-box;font-size: 0.28rem;}
.search .search-ipt .sure{font-size:0.28rem;background: #0084ff;width: 15%;border-radius: 0.36rem;height: 0.72rem;line-height: 0.72rem;text-align: center;color: #fff;}
.search .search-ipt .cancel{font-size:0.28rem;background: #f5f5f5;width: 15%;border-radius: 0.36rem;height: 0.72rem;line-height: 0.72rem;text-align: center;color: #999;}

.search .search-his{padding:0.2rem 0;}
.search .search-his .tittle{font-size:0.3rem;color:#333;text-align: left;}
.search .search-his .items{display: flex;align-content: flex-start;flex-wrap: wrap;}
.search .search-his .items .item{font-size: 0.28rem;max-width: 2rem;background: #f5f5f5;height: 0.64rem;line-height: 0.64rem;text-align: center;color: #666;border-radius: 0.32rem;padding:0 0.3rem;margin: 0.2rem 0.2rem 0 0;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}

.search .search-hot{padding:0.2rem 0;}
.search .search-hot .tittle{font-size:0.3rem;color:#333;text-align: left;}
.search .search-hot .items{display: flex;align-content: flex-start;flex-wrap: wrap;}
.search .search-hot .items .item{font-size: 0.28rem;max-width: 2rem;background: #f5f5f5;height: 0.64rem;line-height: 0.64rem;text-align: center;color: #666;border-radius: 0.32rem;padding:0 0.3rem;margin: 0.2rem 0.2rem 0 0;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}

.bg-f4f5f7{background:#fff;}
.paymargin .head{background: #0785f8;padding: 0 0.4rem;color: #fff;margin-bottom: 0.2667rem;}
.paymargin .head .info{display: flex;justify-content: space-between;height: 2.32rem;border-bottom: 1px solid #fff;padding: 0 0.1333rem;}
.paymargin .head .info .label{font-size: 0.36rem;padding-top: 1.0667rem;}
.paymargin .head .info .price{display: flex;font-size:0.4rem;text-align: right;padding-top:0.8rem;}
.paymargin .head .info .price .num{font-size: 0.88rem;font-family: DIN-Bold;}
.paymargin .head .tips{display: flex;height:0.9867rem;align-items: center;padding: 0 0.1333rem;padding-bottom: 0.08rem;}
.paymargin .head .tips .ico{width: 0.3733rem;height:0.3733rem;margin-right: 0.2133rem;vertical-align: middle;background:url(../img/icon_tishi2.png) no-repeat;background-size: contain;}
.paymargin .head .tips .txt{flex: 1;font-size:0.26rem;color: #fff;}
.paymargin .rules{background: #fff;margin-bottom:0.2667rem;padding:0.4rem 0.5333rem;}
.paymargin .rules .txt{font-size: 0.3rem;color: #666;line-height: 1.5;}
.paymargin .rules .txt .strong{color: #36c300;}
.paymargin .sign-up{background: #fff;height:1.4rem;display: flex;align-items: center;padding: 0 0.5333rem;font-size: 0.3rem;color: #666;}
.paymargin .sign-up .label{color: #000;margin-right:0.8rem;}
.paymargin .sign-up .name{flex: 1;font-size:0.3rem;margin-right:0.8rem;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.paymargin .tip-item{padding:0.2667rem 0.5333rem 0.32rem;}
.paymargin .tip-item .txt{font-size:0.3rem;color: #929292;line-height: 1.4;}
.paymargin .agreement{background: #fff;height:1.4rem;line-height: 1.4rem;display: flex;padding: 0 0.5333rem;align-items: center;}
.paymargin .agreement .ico{width: 0.4rem;height: 0.4rem;margin-right: 0.2667rem;background:url(../img/icon_tongyi2.png) no-repeat;background-size: contain;}
.paymargin .agreement .txt{font-size: 0.3rem;color: #333;}
.paymargin .agreement .txt .span{color: #3c76ff;}
.paymargin .fixed-bottom-zw{height: 0;}
.paymargin .fixed-bottom{height: 1.3333rem;position: fixed;bottom: 0;left: 0;right: 0;}
.paymargin .fixed-bottom-btn{background: linear-gradient(90deg, #0470f5, #088af9);line-height: 1.3333rem;text-align: center;color: #fff;font-size:0.32rem;font-weight: bold;height:1.3333rem;position: fixed;bottom: 0;left: 0;right: 0;border-radius: 0;}


/*弹窗*/
.bg-pop{position: fixed;top: 25%;border-radius: 0.5rem;width: 80%;left: 10%;}
.pop{height:6rem;padding:0.6rem 0.4rem 0.4rem;background:url(https://cdn.huizhuanvip.com/jianzhi/app/images/icon_tc.png) no-repeat;background-size: contain;}
.pop .pop-img{box-sizing: border-box;width: 0.5rem;position: absolute; right: 0.75rem; top: 0.1rem;}
.pop .pop-ipt{font-size: 0.32rem;color: #fff;width: 70%;margin: 3rem auto 0;}
.pop .pop-img img{width: 100%;}
.pop .pop-btn{width: 70%;display: flex;flex-direction: row;align-items: center;justify-content: space-between;margin:0.15rem auto 0;}
.pop .pop-btn .sure{font-size:0.3rem;background: #fff000;width: 45%;border-radius: 0.4rem;height: 0.8rem;line-height: 0.8rem;text-align: center;color: #f0514a;}
.pop .pop-btn .cancel{font-size:0.3rem;background: #fff000;width: 45%;border-radius: 0.4rem;height: 0.8rem;line-height: 0.8rem;text-align: center;color: #f0514a;}

/*页面加载*/
.beijing{width: 100%;background-color: #fff;height: 100%;}
/*分享公众号*/
.bg-040360{background:#040360;}
.gzwx{max-width:100vw;height:100vh;background:url(../img/wx_bg.jpg) no-repeat;background-size: cover;margin:0 auto;position: relative;background-position: top;}
/* .gzwx .head{height: 4.8933rem;} */
.gzwx .head-img{max-width: 6.5rem;}
.gzwx .top-line{margin:0.3rem 0 0 0.80rem;width:6.16rem;height:1.2267rem;background: url(../img/wx_top_bg.png) no-repeat;background-size: contain;padding-top:0rem;box-sizing: border-box;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;}
.gzwx .top-line .avatar{width:0.60rem;height:0.60rem;border:1px solid #05046a;border-radius:0.30rem;margin:0 0.1rem  0 0.5rem;vertical-align: middle;}
.gzwx .top-line .txt{font-size:0.32rem;color:#fff;display: inline-block;}
.gzwx .info{padding-left:0.8533rem;padding-top:0.6rem;}
.gzwx .bor{width:0.5333rem;height:0.08rem;background:#fff;}
.gzwx .info .txt{font-size:0.32rem;color:#fff;font-weight: bold;line-height:2;margin-bottom:0.3rem;}
.gzwx .info .bor{margin-bottom:0.3rem;}
.gzwx .slide2{background:#192680;border-radius:0.1rem;padding:0 0.4rem;box-sizing: border-box;margin: 0 0.8rem;border-radius: 1rem;}
.gzwx .slide2 .item{height:0.84rem;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;justify-content: space-between;}
.gzwx .slide2 .item .avatar{width:0.60rem;height:0.60rem;border:1px solid #05046a;border-radius:0.30rem;margin:0 0.2rem 0 0.1rem;vertical-align: middle;}
.gzwx .slide2 .item .txt{font-size:0.24rem;color:#fff;margin-left:0.1rem;}
.gzwx .slide2 .item .name{margin:0;}
.gzwx .slide2 .item span{color:#fff000;}
.gzwx .wx{font-size:0.36rem;font-weight: bold;color:#fff;padding-left:0.8533rem;padding-top:1.0rem;line-height: 1.8;position: relative;}
.gzwx .wx .bor{margin-bottom:0.4rem;}
.gzwx .qr{width:2.1333rem;height:2.1333rem;position: absolute;right:1.0rem;top: 1.0rem;}
.gzwx .qr,.gzwx .qr .wxqr{width: 2.1333rem;height:2.1333rem;}

.imagePicker{width: 1rem;height: 1rem; border:1px solid #ebebeb;}
.imagePicker .image{width: 1rem;height: 1rem;}
.bg{
  background-color: rgba(0, 0, 0, 0.75);
  width: 3rem;
  height: 3rem;
  margin: auto;
  margin-top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10%;
}
.example {
  width: 0.9rem;
}
.sk-chase {
  width: 0.9rem;
  height: 0.9rem;
  position: relative;
  animation: sk-chase 2.5s infinite linear both; 
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: sk-chase-dot 2.0s infinite ease-in-out both; 
  
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

.text{
  font-size: 0.3rem;
  color: #fff;

}

@keyframes sk-chase {
  100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
}


.picker-list .am-list-item .am-list-line .am-list-extra {
  flex-basis: initial;
}

.test {
  position: relative;
  border-bottom: 0;
}

.test:after {
  content: '';
  position: absolute;
  background-color: #ddd;
  display: block;
  z-index: 1;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1PX;
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transform: scaleY(0.5);
  -ms-transform: scaleY(0.5);
  transform: scaleY(0.5);
}
.date-picker-list .am-list-item .am-list-line .am-list-extra {
  flex-basis: initial;
}
.upload{
  width: 1rem;
}
/* 登录样式 */


.signup{
	font-size: 0.4rem;
	color: #333;
	margin: 0 0.6rem;
	display: flex;
	flex-direction:column;
	justify-content: space-between;
	height: 100vh;
}

.signup-logo{
	font-size: 0.48rem;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 15%;
  margin-bottom: 5%;
}

.signup-logo img{
	width: 1rem;
	height: 1rem;
	border-radius: 0.15rem;
}

.signup-logo span{
	margin-left: 0.15rem;

}

.signup-from{
	margin-top: 2rem;
}

.signup-from div{
	
}


.signup-number{
	background-color: #f0f0f0;
	height: 1rem;
	border-radius: 0.1rem;
	border: 0.01rem solid #ebebeb;
	margin-bottom: 0.3rem;
}

.signup-number input{
	background-color: #f5f5f5;
	width: 100%;
	height: 1rem;
	border-radius: 0.1rem;
	font-size: 0.32rem;
	padding: 0 0.3rem;
	box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.sign-from input::-webkit-input-placeholder{/* WebKit browsers 适配谷歌 */
  color: #BDCADA;
  font-size: 0.30rem;
}
.sign-from input:-moz-placeholder { /* Mozilla Firefox 4 to 18 适配火狐 */
  color: #BDCADA;
  font-size: 0.30rem;
}
.sign-from input::-moz-placeholder { /* Mozilla Firefox 19+ 适配火狐 */
  color: #BDCADA;
  font-size: 0.30rem;
}
.sign-from input:-ms-input-placeholder { /* Internet Explorer 10+  适配ie*/
  color: #BDCADA;
  font-size: 0.30rem;
}


.signup-account{
	background-color: #f5f5f5;
	height: 1rem;
	border-radius: 0.1rem;
	border: 0.01rem solid #ebebeb;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0.6rem;
}

.signup-account input{
	background-color: #f5f5f5;
	width: 65%;
	height: 1rem;
	border-radius: 0.1rem;
	font-size: 0.32rem;
	padding: 0 0.3rem;
	box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.signup-account .signup-account-num{
	color: #3992ff;
	font-size: 0.28rem;
	text-align: center;
    width: 35%;
}

.signup-load{
	width: 100%;
	height: 1rem;
	font-size: 0.4rem;
	margin-bottom: 0.3rem;
}

.signup-load .am-button{
	width: 100%;
	height: 1rem;
	line-height: 1rem;
	font-size: 0.32rem;
	border: 0.01rem solid #1e74de;
	background-color: #3992ff;
	color: #fff;
	border-radius: 0.1rem;
}

.signup-back{
	width: 100%;
	height: 1rem;
	font-size: 0.32rem;
	margin-bottom: 5%;
	
}

.signup-back button{
	width: 100%;
	height: 1rem;
	font-size: 0.32rem;
	background-color: #fff;
	color: #3992ff;
	border-radius: 0.1rem;
	border: 0.015rem solid #3992ff;
}

.signup-tips{
	font-size: 0.24rem;
	color: #999;
}

.signup-tips p{
  margin-bottom: 0.1rem;
  /* line-height: 1.5; */
}

.signup-foot{
	color: #999;
	font-size: 0.24rem;
	/* text-align: center; */
	margin-bottom: 10%;
	margin-top: 5%;
}
/* 报名增加标签选择，面试记录 */
.navigation { background: linear-gradient(0deg, rgba(80, 199, 248, 1), rgba(68, 153, 248, 1)); border-radius: 0rem 0rem 0.4rem 0.4rem; width: 100%; padding-top: 0.4rem;position: relative; overflow-y:hidden;}
.navigation_up { display: flex; align-items: center; height: 0.22rem; }
.navigation_title { font-size: 0.09rem; font-family: 'PingFang-SC-Bold'; font-weight: bold; color: rgba(255, 255, 255, 1); text-align: center; flex: 1; }
.chooseBtn { display: flex; justify-content: space-between; margin: 0 0.4rem; height: 0.2rem; align-items: flex-end; }
.chooseBtn_itemBtn { display: flex; flex-direction: column; align-items: center; justify-content: center; width: 25%; }
.selected .name { font-size: 0.3rem; font-family: 'PingFang-SC-Bold'; font-weight: bold; color: rgba(255, 255, 255, 1); }
.unselected .name { font-size: 0.3rem; font-family: 'PingFang-SC-Regular'; color: rgba(255, 255, 255, 0.6); }
.selected .line { margin-top: 0.09rem; width: 0.4rem; height: 0.06rem; background: white; border-radius: 0.06rem; }
.unselected .line { margin-top: 0.09rem; width: 0.54rem; height: 0.1rem; background: rgba(255, 255, 255, 0); border-radius: 0.05rem; }

/* 面试记录 */
.interviewRecords{ background: rgba(255, 255, 255, 1); border-radius: 0.2rem; padding-bottom: 0.25rem; }
.interviewRecords .interviewRecords_head{ display: flex; align-items: flex-end; justify-content: space-between; }
.interviewRecords .interviewRecords_head .btn{ color: white; text-align: center; height: 0.45rem; line-height: 0.45rem; background:linear-gradient(90deg,rgba(4,112,245,1),rgba(8,138,249,1)); box-shadow:0 0.01rem 0.05rem 0 rgba(4,112,245,0.36); border-radius:0.45rem;  font-size: 0.24rem; width: 1.2rem; margin: 0; margin-right: 0.4rem;}

.interview-list{ width: 100%; }
.interview-list .ms-head{ font-size: 0.3rem; font-weight: bold; display: flex; align-items: center; justify-content: space-between; color: #333; margin-bottom: 0.3rem; }
.interview-list .ms-head .title{ width: 80%; font-size: 0.3rem; font-weight: bold; color: #333; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; padding-right: 0.2rem; }
.interview-list .ms-body{ background-color: #faf9fa; padding: 0.3rem; border-radius: 0.3rem; font-size: 0.28rem; color: #333; }
.interview-list .ms-body .items{ display: flex; align-items: center; justify-content: space-between; }
.interview-list .ms-body .items:not(:last-child){ margin:0 0 0.2rem; }
.interview-list .ms-body .items .item{ display: flex; align-items: flex-start; }
.interview-list .ms-body .items .state{ color: #ff6600; }
.interview-list .ms-body .items .item .title{ width: 1.4rem; text-align: justify; white-space: nowrap; }
.interview-list .ms-body .items .item .content{ display: inline-block; word-wrap: break-word; word-break: break-all; }
.interview-list .ms-body .items .item .img{ width: 0.32rem; height: 0.32rem; margin: 0 0.2rem 0; }
/* 报名详情，职位反馈 */
.reportRecords{ background: rgba(255, 255, 255, 1); border-radius: 0.2rem; padding-bottom: 0.25rem; }
.reportRecords .reportRecords_head{ display: flex; align-items: flex-end; justify-content: space-between; }
.reportRecords .reportRecords_head .btn{ color: white; text-align: center; height: 0.45rem; line-height: 0.45rem; background:linear-gradient(90deg,rgba(4,112,245,1),rgba(8,138,249,1)); box-shadow:0 0.02rem 0.1rem 0 rgba(4,112,245,0.36); border-radius: 0.45rem;  font-size: 0.24rem; width: 1.2rem; margin: 0; margin-right: 0.4rem; }
.reportRecords .reportRecords-body{ padding-left: 0.3rem; }
.reportRecords .reportRecords-body .items{ font-size: 0.28rem; color: #333; }
.reportRecords .reportRecords-body .items:not(:last-child){ margin-bottom: 0.2rem; }
.reportRecords .reportRecords-body .img{ width: 1.2rem; height: 1.2rem; border-radius: 0.2rem; }
.reportRecords .reportRecords-body .times .time{ position: relative; }
.reportRecords .reportRecords-body .times .time::before{ content: ''; position: absolute; width: 0.12rem; height: 0.12rem; border-radius: 0.1rem; left: -0.3rem; top: 0.15rem; background-color: rgba(235, 235, 235, 1); }
/* 隐私政策 */
.all{
	word-break:break-all;
	background-color: #fff;
	min-width: 2rem;
	max-width: 30rem;
	margin: 0 auto; 
	padding: 1rem 0.6rem;
	}
	
	.all h1{
	word-break:break-all;
	padding: 0.1rem 0 0.2rem 0; 
	text-align: center; 
	font-family: "Microsoft YaHei",微软雅黑,"MicrosoftJhengHei",华文细黑,STHeiti,MingLiu;
	font-size:0.5rem; 
	}
	
	.all h4{
	word-break:break-all;
	font-family: "Microsoft YaHei",微软雅黑,"MicrosoftJhengHei",华文细黑,STHeiti,MingLiu;
	font-size:0.4rem
	}
	
	.all p{
	word-break:break-all;
	font-size: 0.3rem;
	line-height: 0.6rem;
	font-family: "Microsoft YaHei",微软雅黑,"MicrosoftJhengHei",华文细黑,STHeiti,MingLiu 
	}
.loginyszc{
	color: #3992ff;
}

/*求职详情*/

.details {
  background: #ebebeb;
}

.details .transparentLine {
  height: 0.2rem;
  background: rgba(255, 255, 255, 0);
}

.details .details_up {
  background: white;
  border-radius: 0.2rem;
}

.settlementRecords {
  background: rgba(255, 255, 255, 1);
  border-radius: 0.2rem;
  padding-bottom: 0.25rem;
}

.details .noRecords {
  font-size: 0.24rem;
  font-family: PingFang-SC-Medium;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}

.details .applicationHistory {
  background: rgba(255, 255, 255, 1);
  border-radius: 0.2rem;
  padding-bottom: 0.25rem;
}

.details .details_bottom {
  height: 0.6rem;
  background: rgba(255, 255, 255, 1);
  position: fixed;
  width: 100%;
  border-top: 0.01rem solid #e8e8e8;
  bottom: 0px;
  box-shadow: -0.1rem 0 4 0.1rem #000 0.3;
}

/* 上部分css */

.details .details_up_jobTitle {
  display: flex;
  padding-top: 0.45rem;
  margin: 0 0.35rem;
  padding-bottom: 0.35rem;
  justify-content: space-between;
  align-items: center;
}

.details .jobName {
  font-size: 0.3rem;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
  /*超出部分隐藏*/
  overflow: hidden;
  /*不换行*/
  white-space: nowrap;
  /*添加...*/
  text-overflow: ellipsis;
}

.details .communicateStatus {
  font-size: 0.3rem;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
  width: 1.8rem;
  text-align: right;
}

.details .company {
  margin: 0 0.35rem 0.32rem 0.35rem;
  display: flex;
  align-items: center;
}

.details .company_image {
  width: 0.36rem;
  height: 0.36rem;
  border-radius: 50%;
  margin-right: 0.15rem;
}

.details .company_name {
  /*超出部分隐藏*/
  overflow: hidden;
  /*不换行*/
  white-space: nowrap;
  /*添加...*/
  text-overflow: ellipsis;
  font-size: 0.24rem;
  font-family: PingFang-SC-Medium;
  color: rgba(102, 102, 102, 1);
}

.details .dottedLine {
  height: 0.01rem;
  border-top: 0.01rem solid #ebebeb;
  margin: 0 0.35rem;
}

.details .pointImg {
  width: 0.18rem;
  height: 0.35rem;
  margin-left: 0.2rem;
}

.details .up_body {
  margin: 0 0.35rem;
  padding-top: 0.4rem;
}

.details .bodyStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.42rem;
}

.details .bodyStyle_title {
  font-size: 0.28rem;
  font-family: PingFang-SC-Medium;
  color: rgba(102, 102, 102, 1);
}

.details .bodyStyle_text {
  font-size: 0.28rem;
  font-family: PingFang-SC-Medium;
  color: rgba(51, 51, 51, 1);
}

.details .bodyStyle_money {
  display: flex;
  align-items: center;
}

.details .bodyStyle_text2 {
  font-size: 0.28rem;
  font-family: PingFang-SC-Medium;
  color: #f60;
}

.details .bodyStyle_note {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.42rem;
  margin: 0 0.35rem;
  padding-top: 0.4rem;
}

.details .bodyStyle_note_text {
  font-size: 0.24rem;
  font-family: PingFang-SC-Medium;
  color: rgba(51, 51, 51, 1);
  width: 4rem;
  text-align: right;
}

/* 结算css */

.details .settlementRecords_title {
  padding-top: 0.40rem;
  margin-left: 0.32rem;
  margin-right: 0.32rem;
  color: #666;
  width: 1.22rem;
  font-size: 0.3rem;
  font-family: PingFang-SC-Medium;
}

.details .settlementRecords_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0.35rem;
}

.details .details_time {
  font-size: 0.24rem;
  font-family: DIN-Medium;
  color: #666;
  width:35%;
}

.details .details_money {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
}

.details .moneyNum1 {
  font-size: 0.24rem;
  font-family: DIN-Medium;
  color: #333;
  text-align: right;
}

/* .moneyNum2 {
  font-size: 0.24rem;
  font-family: DIN-Medium;
  color: #f60;
  text-align: right;
} */

.details .moneyNum2 {
  font-size: 0.24rem;
  font-family: DIN-Medium;
  color: #f60;
  text-align: right;
}

.details .moneyUnit {
  font-size: 0.24rem;
  font-family: DIN-Medium;
  color: #666;
}

.details .details_state {
  display: flex;
  align-items: center;
  font-size: 0.24rem;
  font-family: PingFang-SC-Medium;
  color: #333;
  /* width: 25%; */
  justify-content: flex-end;
}

.details .details_title {
  display: flex;
  align-items: center;
  font-size: 0.24rem;
  font-family: PingFang-SC-Medium;
  color: #333;
  width: 20%;
  justify-content: flex-end;
}

/* 报名css */

.details .applicationHistory_title {
  padding-top: 0.4rem;
  margin-left: 0.32rem;
  margin-right: 0.32rem;
  padding-bottom: 0.15rem;
  color: #666;
  width: 122;
  font-size: 0.3rem;
  font-family: PingFang-SC-Medium;
}

.details .applicationHistory_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.15rem 0.35rem;
}

.details .details_left {
  display: flex;
  align-items: center;
}

.details .details_left_point {
  width: 0.12rem;
  height: 0.12rem;
  background: rgba(235, 235, 235, 1);
  border-radius: 50%;
  margin-right: 0.18rem;
}

.details .details_left_time {
  font-size: 0.28rem;
  font-family: DIN-Medium;
  color: rgba(102, 102, 102, 1);
}

.details .details_right {
  display: flex;
  align-items: center;
}

.details .details_right1 {
  font-size: 0.28rem;
  font-family: PingFang-SC-Medium;
  color: rgba(51, 51, 51, 1);
}

.details .details_right2 {
  font-size: 0.24rem;
  font-family: PingFang-SC-Medium;
  color: #4b9bff;
}

/* 底部css */

.details .details_bottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.details .reportC {
  font-size: 0.24rem;
  font-family: PingFang-SC-Medium;
  color: rgba(255, 102, 0, 1);
  text-align: center;
}

.details .hostingC {
  height: 0.56rem;
  width: 1.80rem;
  border: 0.01rem solid rgba(235, 235, 235, 1);
  border-radius: 0.28rem;
  font-size: 0.24rem;
  font-family: PingFang-SC-Medium;
  color: rgba(102, 102, 102, 1);
  text-align: center;
  line-height: 0.56rem;
}

.details .payC {
  height: 56rem;
  width: 180rem;
  border: 1rem solid rgba(235, 235, 235, 1);
  border-radius: 0.28rem;
  font-size: 0.24rem;
  font-family: PingFang-SC-Medium;
  color: rgba(102, 102, 102, 1);
  text-align: center;
  line-height: 0.56rem;
}

.details .evaluationC {
  width: 1.60rem;
  height: 0.56rem;
  background: linear-gradient(90deg, rgba(4, 112, 245, 1), rgba(8, 138, 249, 1));
  box-shadow: 0rem 0.03rem 0.09rem 0.02rem rgba(0, 111, 229, 0.2);
  border-radius: 0.28rem;
  font-size: 0.24rem;
  font-family: PingFang-SC-Medium;
  color: rgb(10, 10, 10);
  text-align: center;
  line-height: 0.56rem;
}

/* .am-modal-wrap{z-index: 222 !important} */
/* 分享模态框 */
.popbg{position: fixed;top:0;left:0;right:0;bottom:0;background:rgba(0, 0, 0, 0.5);z-index: 1000;display: flex;align-items: flex-end;}
.status_box{width: 100%;height: 2.6rem;background:rgba(255,255,255,255);border-radius:0.20rem; animation: change 0.8s;background-size: cover;/**position: absolute;*/display: flex;bottom:0.10rem;align-items: center;justify-content: center; }
.status_box .box_item{width: 3.8rem;display: flex;flex-direction: column;align-items: center;justify-content: center; }
.status_box .box_item .img{width: 0.96rem;height: 0.96rem;display:inline-block;align-items: center;}
.status_box .box_item .text{font-size: 0.28rem;font-family: PingFang-SC-Medium;color: rgba(0, 0, 0, 1);}
.status_box button{background-color: #fff;}
/* 分享职位海报 */
.poster_box{width: 100%;background:rgba(255,255,255,255);border-radius:0.20rem 0.20rem 0 0; animation: change 0.8s;background-size: cover;display: flex;flex-direction: column;align-items: center}
.poster_box .poster_close{width: 24px;height: 24px;position: absolute;right: 10px;margin-top: 10px;}
.poster_box .poster_content{width: 4.70rem;height: 8.37rem;margin-top:40px;}
.poster_box .guide{font-size: 0.28rem;margin-top: 0.20rem;margin-bottom: 0.20rem;color: #ccc;}
.poster_box .poster_save_btn{margin-top: 3%;margin-bottom: 3%;}
.poster_box .btn{width: 6.7rem;height: 0.8rem;background: #319bff;border-radius: 5px;display: flex;align-items: center;justify-content: center;font-size: 0.32rem;color: #fff;}
.poster_box .poster_save_img{width: 20px;height: 20px;background-size: contain;display: inline-block;vertical-align: middle;margin-right: 0.13rem;}


/* 暑假工 */
.wrap{width:100%;height:100%;position: relative;}
.wrap .moduleJobBg{position: absolute;top:0;left:0;right:0;}
.wrap .moduleJobBg .img{width:100%;}
.body{position: relative;}
.wrap .slogan{text-align: center;padding-top:0.8rem;}
.wrap .slogan .img{height:1.58rem;}

.wrap .content{background:#fff;position: fixed;bottom:0;left:0;right:0;top:3rem;border-radius:0.30rem 0.30rem 0 0;}
.wrap .content .moduleJobBg,.wrap .content .moduleJobBg .img{width:100%;height:100%;z-index: 10;}
.wrap .content .mt{height:0.9rem;display: flex;align-items: center;padding:0 0.4rem;z-index: 100;position: relative;}
.wrap .content .mt .h2{flex:1;font-size:0.32rem;color:#333;font-weight: bold;}
.wrap .content .mt .ico{position: fixed;right: 0;top: 2.4rem;}
.wrap .content .mt .ico .img{width:1.6rem;height:1.56rem;}
.wrap .content .mc{overflow-y: scroll;position: absolute;bottom:0;top:0.9rem;right:0;left:0;z-index: 100;}
.wrap .content .mc .item{margin:0 0.3rem;padding:0.3rem 0;border-bottom:0.01rem solid #ebebeb;}
.wrap .content .mc .am-list-body{background:none;}

.wrap .content .item .name{font-size:0.32rem;color:#333;font-weight: bold;}
.wrap .content .item .price{font-size:0.24rem;color:#ff877e;display: flex;align-items: center;}
.wrap .content .item .price .num{font-size:0.36rem;font-weight: bold;}
.wrap .content .item .ct,.wrap .content .item .cc{display: flex;align-items: center;}
.wrap .content .item .ct{margin:0 0 0.2rem 0;}
.wrap .content .item .tags,.wrap .content .item .name{flex: 1;}
.wrap .content .item .tags{margin-left:-0.2rem;}
.wrap .content .item .tags .tag{border-right:0.01rem solid #666;padding:0 0.2rem;height:0.24rem;font-size:0.24rem;}
.wrap .content .item .tags .tag:last-child{border-right:none;}
.wrap .content .item .tag{font-size:0.24rem;color:#666; }
.wrap .content .item .label{height:0.36rem;line-height:0.36rem;padding:0 0.20rem;border-radius:0.18rem;color:#fff;font-size:0.20rem;background:#ff877e;}
.wrap .content .more{text-align: center;font-size:0.24rem;color:#ff877e;padding:0.3rem 0 0.4rem;}

/* 暑假工-被分享人 */
.bg-ffc03a{background:#ffc03a;}
.sjjz-page{width:100%;padding-bottom: 10%;background:url(../img/shared_module_job_bg.jpg) repeat top center;background-size: contain;}
.sjjz{padding-top:3.7rem;margin:0 auto;background:url(../img/shared_module_job_bg_01.jpg) no-repeat top center;background-size: contain;}
.sjjz .user-items{text-align: center;margin-bottom:0.16rem;}
.sjjz .user-items .item .pic{display: inline-block;vertical-align: middle;}
.sjjz .user-items .item .pic img{width:0.8rem;height:0.8rem;border-radius:0.5rem;border:2px solid #333;margin-right: 0.1rem;}
.sjjz .user-items .item .txt{font-size:0.363rem;font-weight: bold;color:#333;display: inline-block;}
.sjjz .jobs-item{width:100%;margin:0 auto;}
.sjjz .jobs-item .slide{width:90%; height:8.8533rem; padding:0.56rem 0.56rem;background:#fff;border-radius:0.4rem;box-sizing: border-box;border: 3px solid #333;margin: auto;}
.sjjz .jobs-item .item{font-size:0.36rem;color:#333;font-weight: bold;}
.sjjz .jobs-item .item .tag{height:0.8rem;border-radius:0.2rem;padding:0 0.32rem;line-height:0.8rem;background:#ffc03a;display: inline-block;border: 0.0633rem solid #333;}
.sjjz .jobs-item .item .ht1{margin-bottom:0.64rem;}
.sjjz .jobs-item .item .ht2,.sjjz .jobs-item .item .ht3{margin-bottom:0.4rem;}
.sjjz .jobs-item .item .ht4{margin-bottom:0.4rem;}
.sjjz .jobs-item .item .it{overflow: hidden;display: flex;align-items: flex-start;}
.sjjz .jobs-item .item .it label{float: left;white-space: nowrap;margin-bottom: 0.2rem;}
.sjjz .jobs-item .item .it p{padding-left:0.1rem;text-align: justify;line-height: 1.4;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;text-overflow: ellipsis;}
.sjjz .jobs-item .item .labels{margin:0 0.0533rem;display:-webkit-box;display:-webkit-flex;display:flex;}
.sjjz .jobs-item .item .labels .label{line-height:0.6rem;border:0.055rem solid #333;padding:0 0.14rem;margin:0 0.1rem 0 0;border-radius:0.4rem;word-break:break-all;white-space:nowrap;  }
.sjjz .jobs-item .item .labels .label:last-child{margin-right:0;}
.sjjz .jobs-item .dots{text-align: center; margin-top: 0.8rem;}
.sjjz .jobs-item .dots i{width:0.2rem;height:0.2rem;border-radius:0.1rem;background:#d6d6d6;margin:0 0.1rem;display: inline-block;}
.sjjz .jobs-item .dots i.active{background:#333;}
.sjjz .tips{width:88%;margin:0 auto;padding-top:0.5333rem;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align: center;-webkit-align-items: center;align-items: center;}
.sjjz .tips .qr{margin-right:0.3rem;width: 2.2933rem;height: 2.2933rem;border: 3px solid #333;border-radius: 0.3rem;background-color: #fff;}
.sjjz .tips .qr img{width: 2.2933rem;height:2.2933rem;border-radius:0.3rem;}
.sjjz .tips .txt{font-size:0.32rem;color:#333;line-height:1.4;font-weight: bold;}


.swiperC{padding: 0 0.3rem;}



/**/
.bg-713cee{background:#713cee !important;}
.w10rem{max-width:10rem;margin:0 auto;}
.phb .head{width: 8rem;height:5.7rem;background:url("https://cdn.huizhuanvip.com/jianzhi/app/images/integral_bg.png") no-repeat;background-size: cover;}
.phb .phb-wrap{ height:10.9333rem; background:url(../img/bg2.png) no-repeat;background-size: cover;}
.phb .phb-wrap .t{font-size:0.4rem;font-weight: bold;color:#b51733;text-align: center;height:1.2rem;line-height:1.2rem;}
.phb .table{padding:0 0.32rem;padding-top:0.9rem;color: #fff;border-radius: 0.4rem;border: 0.17rem solid yellow;background-color: #fff;margin-top: -0.96rem;}
.phb .table table{width:100%;border-collapse:collapse;}
.phb .table th,.phb .table td{padding:0.28rem 0.1rem;text-align: center;border-bottom:1px solid #e8e8e8;font-size:0.32rem;color:#333;font-weight: bold;}
.phb .table th{width:25%;font-size:0.4rem;}
.phb .table td{max-width:25vw;font-size:0.32rem;height:0.64rem;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.phb .table .num{font-family:Tahoma;}
.phb .table .orange{color:#f35839;}
.phb .table .gray{/* color:#999; */}
.phb .table .num{width:0.5067rem;height:0.64rem;display: inline-block;vertical-align:middle;line-height:0.64rem;}
.phb .table .num.n1{font-size: 0;background:url(../img/j.png) no-repeat;background-size: contain;}
.phb .table .num.n2{font-size: 0;background:url(../img/y.png) no-repeat;background-size: contain;}
.phb .table .num.n3{font-size: 0;background:url(../img/t.png) no-repeat;background-size: contain;}
.phb .rule-mode{margin:0.8rem 0 0 0;padding:0 0.3667rem;}
.phb .phb{margin:-0.5rem 0 0 0;padding:0 0.3667rem;}
.phb .rule-mode .item{background:#5d26e0;border-radius:0.2rem;}
.phb .rule-mode .tit{text-align: center;}
.phb .rule-mode .tit h3{font-size:0.4rem;color:#b51733;text-align: center;display: inline-block;width:5.44rem;height:1.0933rem;background:url(../img/t_bg.png);background-size:cover;line-height:1.2rem;position: relative;}
.phb .rule-mode .tit i{width:0.5067rem;height:0.5067rem;display: inline-block;}
.phb .rule-mode .tit .left{background:url(../img/t_left.png) no-repeat;background-size: contain;}
.phb .rule-mode .tit .right{background:url(../img/t_right.png) no-repeat;background-size: contain;}
.phb .rule-mode .tit span{margin:0 0.2rem;}
.phb .rule-mode .cont{padding:1.2rem 0.4rem 0.4rem;background:#5d26e0;font-size:0.32rem;line-height:1.5;color:#fff;border-radius:0.4rem;margin-top:-0.96rem;border: 0.17rem solid yellow;}
.phb .rule-mode .cont p{padding-bottom: 0.4rem;font-weight: bold;text-align: justify;text-align: justify;}
.phb .rule-mode .cont p:last-child{padding-bottom:0;}
.phb .rule-mode .cont.ct2 p{padding-bottom:0;}
.phb .rule-mode .cont strong{color:#f35839;}
.phb .rule-mode {}
.phb .rule-mode {}
.phb .rule-mode {}
.phb-foot{margin-top:1rem;background:#521fcd;font-size:0.24rem;line-height:1.8;text-align: center;padding:0.3rem 0;color:#a680ff;}

.am-wingblank.am-wingblank-lg {margin: 0;}

/* 修改账户 */
.viewStyle{ padding-top: 1.22rem; margin: 0 0.65rem; }
.change_title{ font-size: 0.24rem; font-family: 'PingFang-SC-Medium'; color:rgba(102,102,102,1); }
.phoneNum{ font-size: 0.48rem; font-family: 'PingFang-SC-Bold'; font-weight:bold; color:rgba(51,51,51,1); margin-top: 0.32rem; }
.newPhoneNumC{ margin-top: 0.62rem; background:rgba(247,247,251,1); font-size: 0.32rem; font-family: 'PingFang-SC-Regular'; color:#333; height: 1.10rem; line-height: 1.10rem; padding-left: 0.31rem; width: 6.5rem;}
.verificationCode_style{ background:rgba(247,247,251,1); font-size: 0.32rem; font-family: 'PingFang-SC-Regular'; height: 1.10rem; line-height: 1.10rem; display: flex; align-items: center; justify-content: space-between; margin-top: 0.32rem; width: 6.85rem;}
.newPhoneNum_placeholderC{ border-radius: 0.08rem; font-size: 0.32rem; font-family: 'PingFang-SC-Regular'; color:#666; }
.verificationCode{ width: 1.85rem; height: 0.74rem; background:rgba(65,127,241,1); border-radius: 0.08rem; font-size: 0.24rem; font-family: 'PingFang-SC-Regular'; color:rgba(255,255,255,1); margin-right: 0.18rem; text-align: center; line-height: 0.74rem; }
.verificationCode1{ width: 1.85rem; height: 0.74rem; background:rgba(230, 230, 230,1); border-radius: 0.08rem; font-size: 0.24rem; font-family: 'PingFang-SC-Regular'; color:rgba(102,102,102,1); margin-right: 0.18rem; text-align: center; line-height: 0.74rem; }
.verificationCodeC{ background:rgba(247,247,251,1); border-radius: 0.08rem; font-size: 0.32rem; font-family: 'PingFang-SC-Regular'; color:#333; height: 1.10rem; line-height: 1.10rem; padding-left: 0.31rem; }
.changeBtnC{ margin-top: 0.52rem; height: 1.10rem; background:#417FF1; border-radius: 0.08rem; font-size: 0.30rem; font-family: 'PingFang-SC-Regular'; color:rgba(255,255,255,1); text-align: center; line-height: 1.10rem; width: 6.85rem;}
.changeBtnD{ margin-top: 0.52rem; height: 1.10rem; background:rgb(50, 107, 214); border-radius: 0.08rem; font-size: 0.30rem; font-family: 'PingFang-SC-Regular'; color:rgba(255,255,255,1); text-align: center; line-height: 1.10rem; }
